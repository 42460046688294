var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-control-wrapper',_vm._b({staticClass:"base-date-picker-container",scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){
var onFocus = ref.onFocus;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDate),expression:"selectedDate"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.selectedDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedDate=$event.target.value}}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto","offset-y":"","disabled":_vm.disabled,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-text-field',_vm._g(_vm._b({ref:_vm.elementId,staticClass:"base-control-container-text",attrs:{"id":_vm.elementId,"data-ruid":("base-date-picker-" + _vm.id),"readonly":"","solo":""},on:{"blur":function () { return onFocus(false); },"focus":function () { return onFocus(true); }},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on),[_c('template',{slot:"append"},[(!_vm.disabled)?_c('inline-svg',{staticClass:"calendar-icon",attrs:{"src":_vm.calendarIcon},on:{"click":on.click}}):_vm._e(),(_vm.disabled)?_c('inline-svg',{staticClass:"calendar-icon-disabled",attrs:{"src":_vm.calendarIconDisabled}}):_vm._e()],1)],2)]},{"attrs":attrs,"on":on})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.minDateCalendar,"type":_vm.calendarType,"first-day-of-week":"1"},on:{"input":_vm.setDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]}},{key:"errors",fn:function(ref){
var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"help",fn:function(){return [_vm._t("help")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))}
var staticRenderFns = []

export { render, staticRenderFns }
import type { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import type { Store } from 'vuex';
import {
  CookieUtils,
  LOGIN_QUERY_PARAMETER,
  SECURITY_COOKIE_NAME
} from '../utils/cookies';
import {
  getCurrentAuth,
  userIsAdmin,
  isAuthenticated
} from '../services/SecurityService';

const authServer = process.env.VUE_APP_AUTHENTICATION_URL;

export default async function processRoute(
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  store: Store<any>, // Vuex.Store
  isAdmin: boolean
): Promise<void> {
  async function fetchCurrentUserIfNeeded() {
    // Fetch current user
    if (isAuthenticated() && !store.getters.currentUser) {
      await store.dispatch('fetchCurrentUser');
    }
  }

  async function loginByToken(toRoute: Route, nextRoute: NavigationGuardNext) {
    await store.dispatch('tokenLogin', toRoute.query[LOGIN_QUERY_PARAMETER]);
    const { query } = toRoute;
    delete query[LOGIN_QUERY_PARAMETER];
    return nextRoute({
      name: toRoute.name as string,
      params: toRoute.params,
      query
    });
  }

  async function loginByCookie(toRoute: Route, nextRoute: NavigationGuardNext) {
    const token = CookieUtils.get(SECURITY_COOKIE_NAME);
    if (!token) {
      await store.dispatch('logout');
    }
    await store.dispatch('tokenLogin', token);
    const { query } = toRoute;
    delete query[LOGIN_QUERY_PARAMETER];
    return nextRoute({
      name: toRoute.name as string,
      params: toRoute.params,
      query
    });
  }

  // Safeguard to not display loading forever in case something happens
  store.dispatch('setGlobalLoading', false);

  // If a cookie login token is passed
  if (to.query[LOGIN_QUERY_PARAMETER] === 'true') {
    return loginByCookie(to, next);
  }

  // If login token is passed
  if (to.query[LOGIN_QUERY_PARAMETER]) {
    return loginByToken(to, next);
  }

  // If the route is public, let it pass
  if (to.matched.some((record: RouteRecord) => record.meta.publicAccess)) {
    return next();
  }

  // Redirect to Login if not authenticated
  if (!isAuthenticated() || (isAdmin && !userIsAdmin(getCurrentAuth()!))) {
    window.location.href = `${authServer}?redirect=${window.location.href}${
      isAdmin ? '&isAdmin=true' : ''
    }`;
    return next(false);
  }

  await fetchCurrentUserIfNeeded();

  // Check LatestTCAcceptance only run on initial load
  if (
    !from.name &&
    to.name !== 'Login' &&
    store.getters.isAuthenticated &&
    store.getters.currentUser?.needToAcceptLatestTC(
      store.getters.getOrganization
    )
  ) {
    return next({ name: 'Login' });
  }

  return next();
}

export type DelegateDomain = 'team' | 'topic' | 'user';

export default class Delegate {
  id: string;

  domain: DelegateDomain;

  constructor(id: string, domain: DelegateDomain) {
    this.id = id;
    this.domain = domain;
  }

  get canonicalId(): string {
    return `${this.domain}:${this.id}`;
  }
}

export interface IDelegatable {
  asDelegate(): Delegate;
}

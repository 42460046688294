import { plainToClass } from 'class-transformer';
import { doGetRequest, doPostRequest, doSecureGetRequest } from './ApiService';
import Organization from '../model/Organization';
import type SSO_MODE from '../model/SsoMode';

function fetchMinimalOrgByDomain(domain: string): Promise<Organization> {
  return doPostRequest<Organization>('/anonymous/organization', {
    domain
  }).then((org) => plainToClass(Organization, org));
}

function fetchCurrentOrganization(): Promise<Organization> {
  return doSecureGetRequest<Organization>('/organization/current').then((org) =>
    plainToClass(Organization, org)
  );
}

function fetchSsoModeOrganization(domain: string): Promise<SSO_MODE> {
  return doGetRequest<SSO_MODE>(`/organization/${domain}/ssoMode`);
}

function fetchSsoLoginOrganization(domain: string): Promise<string> {
  const queryParams = new URLSearchParams(window.location.search);
  let redirectUrl = (queryParams.get('redirect') ||
    process.env.VUE_APP_AUTHENTICATION_URL) as string;

  if (queryParams.get('isAdmin')) {
    const queryParamSeparator = redirectUrl.includes('?') ? '&' : '?';
    redirectUrl = `${redirectUrl}${queryParamSeparator}isAdmin=true`;
  }

  return doGetRequest<string>(
    `/saml2/${domain}/login?returnTo=${redirectUrl}`,
    undefined,
    undefined,
    false
  );
}

export default {
  fetchMinimalOrgByDomain,
  fetchCurrentOrganization,
  fetchSsoLoginOrganization,
  fetchSsoModeOrganization
};

import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { UiState } from '../../types/UiState';
import type { UploadState } from '../../types/UploadState';

const state: UiState = {
  alertMessage: null,
  drawerIsOpen: false,
  showGlobalLoading: false,
  showAlertMessage: false,
  uploadStates: [],
  uploadStatesByInputId: new Map<string, UploadState>()
};

const updateUploadStateByInputId = (source: Array<UploadState>) => {
  state.uploadStatesByInputId = new Map(
    source.map((us: UploadState) => [us.inputId!, us])
  );
};

const getters: GetterTree<UiState, any> = {
  alertMessage: (st) => st.alertMessage,
  drawerIsOpen: (st) => st.drawerIsOpen,
  uploadStateByInputId: (st) => st.uploadStatesByInputId,
  uploadStates: (st) => st.uploadStates,
  showGlobalLoading: (st) => st.showGlobalLoading,
  showAlertMessage: (st) => st.showAlertMessage
};

const mutations: MutationTree<UiState> = {
  toggleDrawer(st: UiState, payload: boolean): void {
    st.drawerIsOpen = payload;
  },
  initUploadState(st: UiState, payload: UploadState): void {
    st.uploadStates = [...st.uploadStates, payload];
    updateUploadStateByInputId(st.uploadStates);
  },
  setUploadProgress(
    st: UiState,
    { inputId, uploadProgress }: UploadState
  ): void {
    st.uploadStates = st.uploadStates.map((acc: UploadState) => {
      if (acc.inputId === inputId) {
        acc.uploadProgress = uploadProgress;
      }
      return acc;
    });
    updateUploadStateByInputId(st.uploadStates);
  },
  removeUploadState(st: UiState, inputId: string): void {
    st.uploadStates = st.uploadStates.filter(
      (acc: UploadState) => acc.inputId !== inputId
    );
    updateUploadStateByInputId(st.uploadStates);
  },
  setGlobalLoading(st: UiState, loading: boolean): void {
    st.showGlobalLoading = loading;
  },
  setAlertMessage(st: UiState, payload): void {
    st.alertMessage = payload.message;
    st.showAlertMessage = payload.show ?? true;
    setTimeout(() => {
      st.alertMessage = null;
      st.showAlertMessage = false;
    }, 7000);
  }
};

const actions: ActionTree<UiState, any> = {
  toggleNavigationalDrawer({ commit }, payload): void {
    commit('toggleDrawer', payload);
  },
  setGlobalLoading({ commit }, payload): void {
    commit('setGlobalLoading', payload);
  },
  setAlertMessage({ commit }, payload): void {
    commit('setAlertMessage', payload);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};






















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import type Ticket from '@rready/common-utils/src/model/Ticket';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import { WorkflowV2 } from '@rready/common-utils/src/model/workflow/v2/WorkflowV2';

import VideoDisplay from './VideoDisplay.vue';

@Component({
  name: 'VideoSwitcher',
  components: {
    VideoDisplay
  }
})
export default class VideoSwitcher extends Vue {
  @Prop({ default: null }) readonly value!: Ticket;

  @Prop({ default: () => ({}) }) readonly providedVideoPitches!: Record<
    string,
    string
  >;

  @Getter getCurrentContext!: string;

  activePitch = '';

  videoObject: FileModel | null = null;

  videoPitchesMap: Record<string, string> = {};

  videoPitches: string[] = [];

  get activePitchTypes(): string[] {
    return Object.values(this.videoPitches).filter(
      (pitchType: string) => !!this.value?.getPitch(pitchType)
    );
  }

  async created(): Promise<void> {
    await this.setInitialVideo();
  }

  @Watch('value')
  async setInitialVideo(): Promise<void> {
    if (!this.value) {
      return;
    }

    const ticketType = this.value.type.toLowerCase();
    const workflowData = await this.$datasource.workflow.find(
      ticketType,
      this.getCurrentContext
    );
    const workflow = new WorkflowV2(workflowData!);

    // Either get the video from the props, or from the workflow
    if (Object.keys(this.providedVideoPitches).length > 0) {
      this.videoPitchesMap = this.providedVideoPitches;
    } else {
      this.videoPitchesMap = workflow.videoPitches;
    }
    // Get all the unique video pitch types
    this.videoPitches = [...new Set(Object.values(this.videoPitchesMap))];
    let initVideo = this.videoPitchesMap[this.value.status!];
    // If not matching video for the phase, elect the "latest/highest" video
    if (!initVideo || !this.activePitchTypes.includes(initVideo)) {
      [initVideo] = Object.values(this.activePitchTypes).slice(-1);
    }
    this.switchActive(initVideo);
  }

  switchActive(videoType: string): void {
    const videoObject = this.value?.getPitch(videoType);
    if (videoObject) {
      this.videoObject = videoObject;
      this.activePitch = videoType;
    }
  }
}

import Vue from 'vue';
import Vuex from 'vuex';
import user from '@rready/common-utils/src/store/modules/user';
import ticket from '@rready/common-utils/src/store/modules/ticket';
import team from '@rready/common-utils/src/store/modules/team';
import ui from '@rready/common-utils/src/store/modules/ui';
import search from '@rready/common-utils/src/store/modules/search';
import context from '@rready/common-utils/src/store/stores/context';
import assessment from './assessment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    ticket,
    team,
    ui,
    search,
    assessment,
    context
  }
});

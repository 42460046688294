import { plainToClass } from 'class-transformer';
import Ticket from '../model/Ticket';
import {
  doSecureDeleteRequest,
  doSecureGetRequest,
  doSecurePostRequest,
  doSecurePutRequest
} from './ApiService';
import type TicketSearchParams from '../types/TicketSearchParams';
import TicketStats from '../types/TicketStats';
import type TicketStatsParams from '../types/TicketStatsParams';
import type Task from '../model/Task';

const config = {
  portalUrl: process.env.VUE_APP_PORTAL_URL
};

function createTicket(newTicket: Ticket, product: string): Promise<Ticket> {
  return doSecurePostRequest<Ticket>(`/ticket/${product}`, newTicket).then(
    (t) => plainToClass(Ticket, t)
  );
}

function getAllTickets(searchBy: TicketSearchParams): Promise<Array<Ticket>> {
  const url = `/ticket/${searchBy.product}?${searchBy.buildUrl()}`;
  return doSecureGetRequest<Array<Ticket>>(url).then((tickets) =>
    plainToClass(Ticket, tickets)
  );
}

function getAssignedTickets(
  searchBy: TicketSearchParams
): Promise<Array<Ticket>> {
  const url = `/ticket/${
    searchBy.product
  }/fetchAssignedTickets?${searchBy.buildUrl()}`;
  return doSecureGetRequest<Array<Ticket>>(url).then((tickets) =>
    plainToClass(Ticket, tickets)
  );
}

function updateTicket(ticketUpdates: Ticket, product: string): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(
    `/ticket/${product}/${ticketUpdates.canonicalId}`,
    ticketUpdates
  ).then((ticket) => plainToClass(Ticket, ticket));
}

function updateTasks(
  parentTicket: Ticket,
  tasks: Array<Task>,
  product: string
): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(
    `/ticket/${product}/${parentTicket.canonicalId}/tasks`,
    { tasks }
  ).then((ticket) => plainToClass(Ticket, ticket));
}

function updateTicketStatus(
  canonicalId: string,
  ticketType: string,
  status: string,
  product: string
): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(
    `/ticket/${product}/${canonicalId}/status`,
    {
      status
    }
  ).then((ticket) => plainToClass(Ticket, ticket));
}

function getTicketById(canonicalId: string, product: string): Promise<Ticket> {
  return doSecureGetRequest<Ticket>(`/ticket/${product}/${canonicalId}`).then(
    (ticket) => plainToClass(Ticket, ticket)
  );
}

async function getTicketStats(
  statsParams: TicketStatsParams
): Promise<TicketStats> {
  const url = `/stats/fetchTicketStats?${statsParams.buildUrl()}`;
  const stats = await doSecureGetRequest<any>(url);
  const byStatus = new Map(
    Object.keys(stats.byStatus).map((s: string) => [
      s,
      parseInt(stats.byStatus[s], 10)
    ])
  );
  return new TicketStats(byStatus, parseInt(stats.total, 10));
}

function removeTicketById(canonicalId: string, product: string): Promise<void> {
  return doSecureDeleteRequest<void>(`/ticket/${product}/${canonicalId}`);
}

function openEditTicketInPortal(product: string, canonicalId: string): void {
  console.warn('[DEPRECATED] Remove navigation from Services');
  const url = `${config.portalUrl}/${product}/edit-ticket/${canonicalId}`;
  window.open(url);
}

function openTicketInPortal(product: string, canonicalId: string): void {
  console.warn('[DEPRECATED] Remove navigation from Services');
  const url = `${config.portalUrl}/${product}/ticket/${canonicalId}`;
  window.open(url);
}

export default {
  createTicket,
  getAllTickets,
  getAssignedTickets,
  getTicketById,
  updateTicket,
  updateTasks,
  removeTicketById,
  updateTicketStatus,
  getTicketStats,
  openEditTicketInPortal,
  openTicketInPortal
};

import { render, staticRenderFns } from "./AppBody.vue?vue&type=template&id=67ddc1f5&"
import script from "./AppBody.vue?vue&type=script&lang=ts&"
export * from "./AppBody.vue?vue&type=script&lang=ts&"
import style0 from "./AppBody.vue?vue&type=style&index=0&id=67ddc1f5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VMain})

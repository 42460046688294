/* eslint-disable prefer-destructuring */
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { instanceToInstance } from 'class-transformer';
import UserService from '../../services/UserService';
import TeamService from '../../services/TeamService';
import OrganizationService from '../../services/OrganizationService';
import WorkflowService from '../../services/WorkflowService';
import analyticsService from '../../services/analyticsService';
import { dataUrlToFile, uploadFile } from '../../services/FileUploadService';
import { setCustomTranslations } from '../translationOverrides';
import {
  getCurrentAuth,
  setCurrentAuth,
  userIsAdmin
} from '../../services/SecurityService';
import type { SingleFileResponse } from '../../types/FileUploadResponse';
import type { UserState } from '../../types/UserState';
import type SSO_MODE from '../../model/SsoMode';
import UserAuthObject from '../../model/UserAuthObject';
import type User from '../../model/User';
import type Organization from '../../model/Organization';
import type Team from '../../model/Team';
import UserSearchParams from '../../types/UserSearchParams';
import type InviteUsersParams from '../../types/InviteUsersParams';
import { setI18nLanguage } from '../../i18n';
import type { UploadDataUrlPayload } from '../../types/UploadDataUrlPayload';
import { CookieUtils, SECURITY_COOKIE_NAME } from '../../utils/cookies';
import { StorageUtils } from '../../utils/storage';

const state: UserState = {
  token: '',
  isAuthenticated: false,
  context: '',
  currentUserId: null,
  currentUser: null,
  hasCompletedProfile: false,
  users: null,
  userById: null
};

const getters: GetterTree<UserState, any> = {
  isAuthenticated: (st) => st.isAuthenticated || !!getCurrentAuth(),
  token: (st) => st.token || getCurrentAuth()?.token,
  currentUserId: (st) => st.currentUserId || getCurrentAuth()?.id,
  currentUser: (st) => st.currentUser,
  hasCompletedProfile: (st, rootGetters) =>
    !!(
      st.currentUser &&
      st.currentUser.firstName &&
      st.currentUser.lastName &&
      st.currentUser.unit &&
      !st.currentUser.needToAcceptLatestTC(rootGetters.getOrganization!)
    ),
  users: (st) => st.users,
  userById: (st) => (id: string) => st.userById?.get(id)
};

const mutations: MutationTree<UserState> = {
  setAuth(st: UserState, payload: UserAuthObject) {
    st.token = payload.token;
    st.context = payload.context;
    st.currentUserId = payload.id;
    st.isAuthenticated = true;
    setCurrentAuth(payload);
  },
  setCurrentUser(st: UserState, payload: User) {
    st.currentUser = payload;
    st.currentUser._hasAdminAccess = (getCurrentAuth()?.roles ?? []).includes(
      'ADMIN'
    );
  },
  setCurrentUserAvatar(st: UserState, avatar: string | null) {
    if (st.currentUser) {
      st.currentUser.avatar = avatar;
    }
  },
  setUsers(st: UserState, users: Array<User>) {
    const newUsers = users.filter((u) => !st.userById?.has(u.id!));
    st.users = [...(st.users || []), ...newUsers];
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },
  setUsersByBatch(st: UserState, users: Array<User>) {
    if (!st.users || !st.users.length) {
      st.users = users;
    } else {
      users.forEach((u) => {
        if (!st.userById || !st.userById.has(u.id!)) {
          st.users?.push(u);
        }
      });
    }
    st.users = instanceToInstance(st.users);
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },
  removeUserById(st: UserState, userId: string) {
    if (!st.users || !st.users.length) {
      return;
    }
    st.users = st.users?.filter((u: User): boolean => u.id !== userId);
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },

  acceptTermsAndConditions(st: UserState, org: Organization) {
    if (st.currentUser && org?.termsAndConditions) {
      const newCurrentUser = instanceToInstance(st.currentUser);
      newCurrentUser.agreedTCVersion = org.termsAndConditions.version;
      st.currentUser = newCurrentUser;
    }
  }
};

const actions: ActionTree<UserState, any> = {
  async setAuthorization({ commit, dispatch }, auth: UserAuthObject) {
    commit('setAuth', auth);
    await dispatch('fetchCurrentUser');
  },

  async login(
    { dispatch },
    { email, password, isAdmin = false }
  ): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.login(email, password);
    if (isAdmin && !userIsAdmin(auth)) {
      throw new Error('No admin privileges');
    }
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async tokenLogin(
    { dispatch },
    token: string,
    isAdmin = false
  ): Promise<UserAuthObject> {
    const auth = UserAuthObject.createFromToken(token);
    if (isAdmin && !userIsAdmin(auth)) {
      throw new Error('No admin privileges');
    }
    await dispatch('setAuthorization', auth);
    CookieUtils.set({
      name: SECURITY_COOKIE_NAME,
      value: token,
      domain: process.env.VUE_APP_DEFAULT_DOMAIN
    });
    return Promise.resolve(auth);
  },

  async register(_context, payload): Promise<boolean> {
    return UserService.register(payload.email, payload.language);
  },

  async confirmRegistration({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.confirmRegistration(
      payload.token
    );
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async forgotPassword(_context, payload): Promise<boolean> {
    return UserService.forgotPassword(payload.email, payload.language);
  },

  async confirmForgotPassword({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.confirmForgotPassword(
      payload.password,
      payload.token
    );
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async setPassword({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.setPassword(
      payload.password
    );
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async fetchCurrentUser({
    dispatch,
    commit,
    getters: uGttrs
  }): Promise<User | null> {
    // USER
    const user = await UserService.fetchUserById(uGttrs.currentUserId);

    // ORGANIZATION

    const organization = await OrganizationService.fetchCurrentOrganization();
    if (organization.enabledProducts.length === 0) {
      console.warn('No enabled products');
    }
    commit('setupOrganization', organization);

    // WORKFLOWS

    const workflows = await Promise.all(
      organization.enabledProducts.map((x) => WorkflowService.fetchWorkflow(x))
    );
    commit('setupWorkflows', workflows);

    // PRODUCT

    let currentProductId: string | null = null;
    const saved = StorageUtils.getProductId();
    if (saved && organization.enabledProducts.includes(saved)) {
      currentProductId = saved;
    } else {
      currentProductId = organization.enabledProducts[0];
    }
    StorageUtils.setProductId(currentProductId);
    commit('setupCurrentProduct', currentProductId);

    console.warn('[DEPRECATED] fetchOrganizationTeams: Move to SDK');
    await dispatch('fetchOrganizationTeams', { root: true });

    // TRANSLATION OVERRIDES

    if (currentProductId) {
      dispatch('updateTranslationOverrides');
      setI18nLanguage(user.language.toLowerCase(), currentProductId);
    }

    analyticsService.setUserData(user);
    analyticsService.initSmartlook(user);

    commit('setCurrentUser', user);
    return Promise.resolve(user);
  },

  async fetchUserById(unused, userId: string): Promise<User | null> {
    return UserService.fetchUserById(userId);
  },

  updateTranslationOverrides({ rootGetters }) {
    // DEPRECATED: Dynamic translations should not update vue-i18n anymore,
    // update logic to use them in each view when possible
    console.warn('[DEPRECATED] updateTranslationOverrides');

    const languages = rootGetters.getOrganization?.availableLanguages ?? ['EN'];
    // eslint-disable-next-line no-underscore-dangle
    const workflow = rootGetters.getProductWorkflow?.innerWorkflow;

    setCustomTranslations(workflow, languages);
  },

  async fetchUsers(
    { commit },
    payload: {
      params: UserSearchParams;
    } = {
      params: new UserSearchParams()
    }
  ): Promise<Array<User>> {
    const users = (await UserService.fetchUsers(payload.params)) || [];

    // Take only the enabled ones
    // Will fix it later in backend
    commit(
      'setUsers',
      users.filter((u: User) => u.enabled)
    );

    return Promise.resolve(users);
  },

  async fetchUsersByBatch(
    { commit, getters: stateGetters, state: userState },
    targets: Array<string>
  ): Promise<Array<User>> {
    const targetIds = targets.filter((id: string) => !!id);
    if (!targetIds.length) {
      return Promise.resolve([]);
    }
    const doesntExistTargets: string[] = [
      ...new Set(
        Array.from(targetIds).filter(
          (_: string) => !userState.userById || !userState.userById.has(_)
        )
      )
    ];
    if (doesntExistTargets.length) {
      const users = await UserService.fetchUsersByBatch(doesntExistTargets);
      commit('setUsersByBatch', users);
    }
    return Promise.resolve(targetIds.map((t) => stateGetters.userById(t)));
  },

  async fetchUserTeams(unused, userId): Promise<Team[]> {
    return TeamService.fetchSubscribedTeams(userId);
  },

  async updateCurrentUser(_context, payload: User): Promise<User> {
    const user: User = await UserService.updateUser(
      _context.getters.currentUserId,
      payload
    );
    _context.commit('setCurrentUser', user);
    _context.commit('removeUserById', user.id);
    _context.commit('setUsers', [user]);
    return Promise.resolve(user);
  },

  async deleteOwnProfile(): Promise<string> {
    const deletedUserId = await UserService.deleteOwnProfile();
    return Promise.resolve(deletedUserId);
  },

  async deleteUser(_context, userId: string): Promise<string> {
    const deletedUserId = await UserService.deleteUser(userId);
    _context.commit('removeUserById', deletedUserId);
    return Promise.resolve(deletedUserId);
  },

  setUserAvatar(_context, imageUrl): void {
    this.commit('setCurrentUserAvatar', imageUrl);
  },

  async uploadFile(
    unused,
    { dataUrl, fileName, prefix }: UploadDataUrlPayload
  ): Promise<SingleFileResponse> {
    const convertedFile = await dataUrlToFile(dataUrl, fileName);
    const res: SingleFileResponse = await uploadFile(
      fileName,
      prefix || 'other/',
      convertedFile
    );
    return Promise.resolve(res);
  },

  async uploadCompanyVideo(
    unused,
    { fileName, file }
  ): Promise<SingleFileResponse> {
    const res: SingleFileResponse = await uploadFile(
      fileName,
      'company/video/',
      file
    );
    return Promise.resolve(res);
  },

  async acceptTermsAndConditions({ commit, rootGetters }): Promise<boolean> {
    const response = await UserService.acceptTermsAndConditions();
    commit('acceptTermsAndConditions', rootGetters.getOrganization);
    return Promise.resolve(response);
  },

  inviteUsersByEmail(unused, payload: InviteUsersParams): Promise<boolean> {
    return UserService.inviteUsersByInvitation(payload);
  },

  removeUserAvatar(): void {
    this.commit('setCurrentUserAvatar', undefined);
  },

  logout(): Promise<void> {
    return UserService.logout();
  },

  // sue me

  fetchOrgAnonymously(unused, email: string): Promise<Organization> {
    const domain = email.substring(email.indexOf('@') + 1, email.length);
    return OrganizationService.fetchMinimalOrgByDomain(domain);
  },

  fetchOrgSsoMode(unused, email: string): Promise<SSO_MODE> {
    const domain: string = email.split('@')[1]?.toLowerCase();
    return OrganizationService.fetchSsoModeOrganization(domain);
  },

  async fetchOrgSsoLogin(unused, email: string): Promise<string> {
    const domain: string = email.split('@')[1].toLowerCase();
    return OrganizationService.fetchSsoLoginOrganization(domain);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};

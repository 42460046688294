import { plainToClass } from 'class-transformer';
import type { DateTimeNumber } from '@rready/sdk';
import UserMetaInfo from './UserMetaInfo';
import UserPreferences from './UserPreferences';
import CommonDto from './CommonDto';
import type Organization from './Organization';
import AspectPrefixType from '../types/AspectPrefixType';

// IP-2228 Review
export interface IUserCompat {
  agreedTCVersion: number;
  avatar: string;
  context: string;
  createdBy: string;
  createdDate: DateTimeNumber;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  language: string;
  lastModifiedBy: string;
  lastModifiedDate: DateTimeNumber;
  lastName: string;
  meta?: {
    about?: string;
    phoneNumber?: string;
  };
  preferences: unknown;
  unit: string;
  username: string;
  version: number;
}

export default class User extends CommonDto {
  public static compat(value: IUserCompat): User {
    return plainToClass(User, value);
  }

  id: string | undefined;

  get name(): string | null {
    console.warn('User.name should not be called');
    return null;
  }

  firstName: string | null = null;

  lastName: string | null = null;

  meta: UserMetaInfo;

  metaPublic: any;

  context: string | undefined;

  username: string | undefined;

  email: string | undefined;

  emailVerified: boolean | undefined;

  enabled: boolean | undefined;

  agreedTCVersion: number | undefined;

  avatar: string | null = null;

  language = 'EN';

  unit: string | undefined;

  _hasAdminAccess = false;

  preferences: UserPreferences;

  constructor() {
    super();
    this.meta = new UserMetaInfo();
    this.preferences = new UserPreferences();
  }

  static removePrefix(galeraId: string): string {
    return galeraId.replace(AspectPrefixType.USER, '');
  }

  static getUserIdsByRole(
    subs: Map<string, number>,
    level = 3200
  ): Array<string> {
    const users: Array<string> = [];
    subs.forEach((subLevel: number, subKey: string) => {
      if (subLevel >= level) {
        users.push(User.removePrefix(subKey));
      }
    });
    return users;
  }

  isAdmin(): boolean {
    return this._hasAdminAccess;
  }

  needToAcceptLatestTC(organization: Organization): boolean {
    if (organization && !organization.termsAndConditions) {
      return false;
    }
    return this.agreedTCVersion !== organization?.termsAndConditions?.version;
  }
}

import { Type, plainToClass } from 'class-transformer';
import type { DateTimeNumber, ILinkResponse } from '@rready/sdk';
import type Topic from './Topic';
import CommonDto from './CommonDto';
import TicketMetaInfo from '../types/TicketMetaInfo';
import type TicketHistory from './TicketHistory';
import { HistoryEventType } from './TicketHistory';
import type TicketOrganization from './TicketOrganization';
import type { FileModel } from '../types/FileModel';
import type { TicketStatusType } from '../types/TicketStatus';
import { TicketStatus } from '../types/TicketStatus';
import type Delegate from './Delegate';

export interface ITicketCompat {
  assignedAt: DateTimeNumber;
  assignee: string;
  canonicalId: string;
  context: string;
  createdDate: DateTimeNumber;
  creator: string;
  creatorTeam: string;
  deadLine: DateTimeNumber;
  delegate: any[];
  files: unknown[];
  history: unknown[];
  id: string;
  lastModifiedByUser: string;
  lastModifiedDate: DateTimeNumber;
  meta: unknown;
  parent: string;
  status: string;
  tasks: ITicketCompat[];
  title: string;
  type: string;
  links: ILinkResponse[];
}

export default class Ticket extends CommonDto {
  public static compat(value: ITicketCompat): Ticket {
    return plainToClass(Ticket, value);
  }

  id: string | undefined;

  canonicalId: string | undefined;

  parent: string | undefined;

  context: string | undefined;

  title: string | undefined;

  assignedAt: Date | undefined;

  deadLine: Date | undefined;

  assignee: string | undefined;

  removeAssignee: boolean | undefined;

  files: Array<FileModel> = [];

  creator: string | undefined;

  creatorTeam: string | undefined;

  delegate: Array<Delegate> = [];

  history: Array<TicketHistory> = [];

  meta: TicketMetaInfo;

  organization: TicketOrganization | undefined;

  status: TicketStatusType | string | undefined;

  links: ILinkResponse[] = [];

  @Type(() => Ticket)
  tasks: Array<Ticket> = [];

  type: string;

  constructor(type: string) {
    super();
    this.meta = new TicketMetaInfo();
    this.type = type;
  }

  get isNewTicket(): boolean {
    return !this.id || this.status === TicketStatus.SCRATCH;
  }

  get cover(): FileModel | undefined {
    // Get the last item from the array to prevent problem with faulty uploads
    return this.getFiles('ticket-cover').slice(-1)[0];
  }

  get coverUrl(): string {
    return this.cover?.url || '';
  }

  getPropValue(prop: string): string {
    return prop.split('.').reduce((result, key) => {
      const value = result[key];
      return Array.isArray(value) && !value.length ? null : value;
    }, this as any);
  }

  getPitch(pitchType: string): FileModel | undefined {
    return this.getFiles(pitchType)[0];
  }

  getProblem(): string | undefined {
    return this.meta?.problem;
  }

  getSolution(): string | undefined {
    return this.meta?.solution;
  }

  getTags(): Topic[] {
    const { tags } = this.meta;
    return tags ? JSON.parse(tags) : [];
  }

  getLinks(): Array<string> {
    if (this.meta && this.meta.link) {
      return this.meta.link.split(' , ');
    }
    return [];
  }

  setLinks(links: Array<string>): string | undefined {
    const link = links.length
      ? links.filter(this.notNullOrBlank).join(' , ')
      : undefined;
    if (this.meta) {
      this.meta.link = link;
    }
    return link;
  }

  notNullOrBlank(value: string): boolean {
    return value !== undefined && value.trim().length > 0;
  }

  // TODO: Remove this and use 'delegate' field
  getDelegateCompat(): Delegate | undefined {
    return this.delegate.length > 0 ? this.delegate[0] : undefined;
  }

  getDelegateID(): string | null {
    console.warn('Ticket.getDelegateID');
    return null;
  }

  getLastValidStatus(ignoreStatuses: string[]): string | undefined {
    const statuses = this.history
      .sort((x, y) => (x.createdDate! > y.createdDate! ? 1 : -1))
      .filter(
        (hist) =>
          hist.eventType === HistoryEventType.STATUS_UPDATED &&
          !ignoreStatuses.includes(hist.newValue!)
      )
      .map((hist) => hist.newValue);
    return statuses.length ? statuses.pop() : this.status;
  }

  getFiles(category: string): FileModel[] {
    return this.files.filter((file: FileModel) => file.category === category);
  }
}

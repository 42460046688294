





















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'BaseUserSearch'
})
export default class BaseUserSearch extends Vue {
  @Prop({ default: '0' }) cardHeight!: string;
}






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'PrimaryButton',
  inheritAttrs: false,
  components: {
    BaseButton
  }
})
export default class PrimaryButton extends Vue {}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-control-container"},[_c('base-control-wrapper',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){return [_c('v-select',{ref:_vm.elementId,staticClass:"base-control-container-select",class:{ 'multiple-select': _vm.$attrs['multiple'] },attrs:{"id":_vm.elementId,"data-ruid":("select-" + _vm.id),"disabled":_vm.$attrs.isDisabled,"height":_vm.$attrs.height || 50,"item-text":_vm.$attrs['item-text'],"item-value":_vm.$attrs['item-value'],"items":_vm.resolvedItems,"loading":_vm.loading || _vm.resolvingItems,"menu-props":{
          contentClass: _vm.$attrs['multiple'] ? 'multiple-select--menu' : '',
          nudgeTop: 1
        },"multiple":_vm.$attrs['multiple'],"placeholder":_vm.$attrs.placeholderText,"readonly":_vm.disabled,"returnObject":_vm.$attrs['return-object'],"value":_vm.$attrs.value,"appendIcon":"mdi-chevron-down","dense":"","flat":"","hide-details":"","persistent-hint":"","single-line":"","solo":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.$attrs['multiple'])?{key:"selection",fn:function(ref){
        var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(((_vm.$attrs.value.length) + " selected"))+" ")]):_vm._e()]}}:(_vm.hasSelectionSlot)?{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._t("selection",null,{"dataRecordingSensitive":"","item":item})]}}:null,{key:"no-data",fn:function(){return [_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t('common.noDataAvailable'))+" ")])]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
        var on = ref.on;
        var attrs = ref.attrs;
return [_vm._t("item",function(){return [(_vm.$attrs.multiple)?_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(attrs.inputValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")])],1):_vm._e(),_c('v-list-item-content',_vm._b({attrs:{"data-recording-sensitive":""}},'v-list-item-content',on,false),[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.getDisplayText(item, _vm.$attrs['item-text']))}})],1)]},{"item":item,"on":on})]}}],null,true)})]}},{key:"errors",fn:function(ref){
        var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
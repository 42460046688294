import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { SearchState } from '../../types/SearchState';
import type Team from '../../model/Team';
import type Ticket from '../../model/Ticket';
import TicketSearchParams from '../../types/TicketSearchParams';

const state: SearchState = {
  foundTeams: [],
  foundTickets: []
};

const getters: GetterTree<SearchState, any> = {
  foundTeams: (st) => st.foundTeams,
  foundTickets: (st) => st.foundTickets
};

const mutations: MutationTree<SearchState> = {
  setFoundTeams(st: SearchState, foundTeams: Array<Team>) {
    st.foundTeams = foundTeams;
  },
  setFoundTickets(st: SearchState, foundTickets: Array<Ticket>) {
    st.foundTickets = foundTickets;
  }
};

const actions: ActionTree<SearchState, any> = {
  async searchTickets(
    { commit, dispatch, rootGetters },
    data: { title: string; ticketType: string; product: string }
  ): Promise<Array<Ticket>> {
    const params = new TicketSearchParams(data.ticketType, data.product);
    params.title = data.title;
    params.status = rootGetters.getProductWorkflow.activeWorkflowStatuses;
    params.type = rootGetters.getProductConfig.ticketType;
    let tickets = new Array<Ticket>();
    tickets = await dispatch('getAllTickets', params);
    commit('setFoundTickets', tickets);
    return Promise.resolve(tickets);
  },

  async searchTeams({ commit, rootGetters }, name: string): Promise<void> {
    let teams = new Array<Team>();
    const allTeams = teams!.concat(rootGetters.organizationalUnits!);
    teams = allTeams!.filter((_: Team) =>
      _.name?.toLowerCase().includes(name.toLowerCase())
    );
    commit('setFoundTeams', teams);
  },

  async viewAllTickets(
    { commit, dispatch, rootGetters },
    params: TicketSearchParams
  ): Promise<Array<Ticket>> {
    const searchParams = params;
    searchParams.type = rootGetters.getProductConfig.ticketType;
    const tickets: Array<Ticket> = await dispatch('getAllTickets', params);
    commit('setFoundTickets', tickets);
    return Promise.resolve(tickets);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};

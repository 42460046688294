enum RoleType {
  ASSESSMENT = 'ASSESSMENT',
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  USER = 'USER',
  SERVICE_PROVIDER_ADMIN = 'SERVICE_PROVIDER_ADMIN',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER'
}

export default RoleType;

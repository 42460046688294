



























import type { ActionMethod } from 'vuex';
import * as Sentry from '@sentry/vue';
import { Action } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import type Ticket from '@rready/common-utils/src/model/Ticket';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import ImageCropper from '../widgets/media/ImageCropper.vue';

@Component({
  name: 'BaseTicketImageUpload',
  inheritAttrs: false,
  components: {
    BaseControlWrapper,
    ImageCropper
  }
})
export default class BaseTicketImageUpload extends BaseFormControl {
  @Prop({ default: null }) readonly ticket!: Ticket;

  @Prop({ default: 'ticket-cover' }) readonly category!: string;

  @Action uploadDataUrl!: ActionMethod;

  @Action removeFile!: ActionMethod;

  value = '';

  async created(): Promise<void> {
    super.created();
    this.value = this.ticket.getFiles(this.category).slice(-1)[0]?.url;
  }

  async handlePhoto(dataUrl: string, file: File | undefined): Promise<void> {
    const oldCover = this.ticket.cover;
    if (oldCover) {
      try {
        await this.removeFile({
          ticketId: this.ticket.canonicalId,
          fileId: oldCover.id
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    this.uploadDataUrl({
      dataUrl,
      fileName: file?.name,
      objectId: this.ticket.canonicalId,
      category: this.category
    });
  }
}







































import type { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import videoIcon from '../../assets/video.svg';
import BaseControlWrapper from '../core/BaseControlWrapper.vue';
import BaseFileCard from './BaseFileCard.vue';
import BaseMenu from './BaseMenu.vue';
import BasePill from './BasePill.vue';
import BaseUpload from './BaseUpload.vue';
import BaseFormControl from '../../helpers/BaseFormControl';

@Component({
  name: 'BaseVideoUpload',
  components: {
    BaseControlWrapper,
    BaseFileCard,
    BaseUpload,
    BasePill,
    BaseMenu
  }
})
export default class BaseVideoUpload extends BaseFormControl {
  @Prop({ default: '' }) readonly videoType!: string;

  @Prop({ default: '' }) readonly initialVideos!: FileModel | null;

  @Prop({ default: '' }) readonly objectId!: string;

  @Action convertVideo!: ActionMethod;

  @Action removeFile!: ActionMethod;

  mediaObject: FileModel | null = null;

  menuOptions: MenuOption[] = [
    {
      title: this.$t('menu.remove').toString(),
      value: 'remove'
    }
  ];

  created(): void {
    super.created();
    this.setVideo();
  }

  @Watch('initialVideos')
  setVideo(): void {
    this.mediaObject = this.initialVideos;
  }

  onMenuOptionClick(): void {
    if (this.mediaObject) {
      this.removeFile({ ticketId: this.objectId, fileId: this.mediaObject.id });
    }
    this.mediaObject = null;
    this.$emit('input', this.mediaObject);
  }

  setMediaObject(newMediaObject: FileModel, convertVideo = false): void {
    this.mediaObject = newMediaObject;
    this.mediaObject.url = videoIcon;
    this.$emit('input', this.mediaObject);
    if (convertVideo) {
      const { objectId, videoType: fieldName } = this;
      this.convertVideo({ objectId, fieldName });
    }
  }
}

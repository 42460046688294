























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';

@Component({
  name: 'BaseLinks',
  components: {
    BaseTextInput,
    BaseAddButton
  }
})
export default class BaseLinks extends Vue {
  @Prop({ default: '' }) value: string | undefined;

  links: Array<string> = [];

  @Watch('value', { immediate: true })
  generateLinks(): void {
    this.links = this.value ? this.value.toString().split(' , ') : [''];
  }

  inputLink(event: string, index: number): void {
    this.links[index] = event;
    this.setLinks(this.links);
  }

  addLink(): void {
    this.links?.push('');
    this.setLinks(this.links);
  }

  removeLink(index: number): void {
    this.links?.splice(index, 1);
    this.setLinks(this.links);
  }

  setLinks(links: Array<string>): void {
    const link = links.length
      ? links.filter(this.notNullOrBlank).join(' , ')
      : undefined;
    this.$emit('input', link);
  }

  notNullOrBlank(value: string): boolean {
    return value.trim().length > 0;
  }
}

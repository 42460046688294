/* eslint-disable import/prefer-default-export */
import type { LocaleMessages as Resources } from 'vue-i18n';
import { merge } from 'lodash';
import type { IWorkflowV2 } from '@rready/sdk';
import { addProductTranslationOverrides } from '../i18n';

function addTransitionDialogTranslations(
  languageMap: Resources,
  workflow: IWorkflowV2
): Resources {
  return workflow.transitions
    .filter((transition) => transition.transitionDialog?.active)
    .reduce((acc: Resources, transition) => {
      Object.keys(languageMap).forEach((language): void => {
        if (!acc[language]) {
          acc[language] = {};
        }
        const translations = transition.transitionDialog?.resources as Record<
          string,
          Record<string, string>
        >;
        const translationKey = `transitionDialog_${transition.transitionFrom}_${transition.transitionTo}`;
        acc[language][translationKey] =
          translations[language] || translations.en;
      });
      return acc;
    }, languageMap);
}

function addStatusTranslations(
  languageMap: Resources,
  workflow: IWorkflowV2
): Resources {
  let newLanguageMap = {};
  Object.entries(workflow.settings.status).forEach(
    ([status, { resources }]) => {
      // If there is no resource bundle provided use the status name itself as the label
      const translations = resources ?? { en: { label: status } };
      const statusResources = Object.entries(translations).reduce(
        (acc: Record<string, any>, [language, i18nMessages]): Resources => {
          if (!acc[language]) {
            acc[language] = { ticket: { status: { [status]: {} } } };
          }
          acc[language].ticket.status[status] = i18nMessages;
          return acc;
        },
        newLanguageMap
      );
      newLanguageMap = merge(languageMap, statusResources);
    }
  );
  return newLanguageMap;
}

export function setCustomTranslations(
  workflow: IWorkflowV2,
  languages: string[]
): void {
  let languageMap: Resources = languages.reduce((acc, lang) => {
    acc[lang.toLowerCase()] = {};
    return acc;
  }, {} as Resources);

  // TODO: Add product specific ticket name from workflow
  // languageMap = {
  //   "en": {
  //     "common": {
  //       "ticket": "project",
  //       "tickets": "projects"
  //     }
  //   }
  // }

  languageMap = addTransitionDialogTranslations(languageMap, workflow);
  languageMap = addStatusTranslations(languageMap, workflow);

  addProductTranslationOverrides(languageMap);
}

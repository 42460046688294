import { render, staticRenderFns } from "./BaseUserAvatar.vue?vue&type=template&id=93667e3a&scoped=true&"
import script from "./BaseUserAvatar.vue?vue&type=script&lang=ts&"
export * from "./BaseUserAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./BaseUserAvatar.vue?vue&type=style&index=0&id=93667e3a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93667e3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VIcon,VImg,VTooltip})

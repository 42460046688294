import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { Prop } from 'vue-property-decorator';
import type BaseFormControlInt from './BaseFormControlInt';

export default class BaseFormControl extends Vue implements BaseFormControlInt {
  @Prop({ default: null }) id!: string;

  @Prop({ default: undefined }) label!: string;

  @Prop({ default: null }) type!: string;

  @Prop({ default: undefined }) placeholder!: string;

  @Prop({ default: null }) field!: string;

  @Prop({ default: false }) loading!: boolean;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: 0 }) delayValidation!: number;

  @Prop({ default: () => [] }) rules!: Array<string>;

  @Prop({ default: undefined }) helpTitle!: string;

  @Prop({ default: undefined }) helpContent!: string;

  public focused = false;

  get elementId(): string {
    return this.id || this.field;
  }

  get helpTitleText(): string | null {
    if (typeof this.helpTitle !== 'undefined') {
      return this.helpTitle;
    }

    return this.$te(`form.${this.field}.helpTitle`)
      ? this.$t(`form.${this.field}.helpTitle`).toString()
      : this.$t(`form.general.helpTitle`, {
          fieldName: this.getLabel(false)
        }).toString();
  }

  get helpContentText(): string | null {
    if (typeof this.helpContent !== 'undefined') {
      return this.helpContent;
    }

    return this.$te(`form.${this.field}.helpContent`)
      ? this.$t(`form.${this.field}.helpContent`).toString()
      : '';
  }

  get labelTitle(): string | null {
    return this.getLabel(this.isRequired());
  }

  get placeholderText(): string {
    if (typeof this.placeholder !== 'undefined') {
      return this.placeholder;
    }
    return this.$te(`form.${this.field}.placeholder`)
      ? this.$t(`form.${this.field}.placeholder`).toString()
      : '';
  }

  get fieldName(): string {
    const fieldName = this.$te(`form.${this.field}.fieldName`)
      ? this.$t(`form.${this.field}.fieldName`).toString()
      : this.getLabel(false);
    return fieldName || this.field;
  }

  get applyRules(): string | number {
    return this.rules.length ? this.rules.join('|') : '';
  }

  getLabel(required: boolean): string | null {
    // if label is set to empty string, return null to hide label
    if (!this.field || (typeof this.label !== 'undefined' && !this.label)) {
      return null;
    }
    const text = this.label || this.$t(`form.${this.field}.label`).toString();

    return required ? `${text}*` : text;
  }

  isRequired(): boolean {
    return this.rules.includes('required');
  }

  validate(): boolean {
    const component: any = this.$refs[this.id || this.field];
    return component.validate();
  }

  created(): void {
    const allRules: any = rules;
    this.rules.forEach((rule) => {
      if (allRules[rule]) {
        extend(rule, allRules[rule]);
      }
    });
  }
}

enum ProjectTicketStatus {
  SCRATCH = 'SCRATCH',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REDBOX_IDEA = 'REDBOX_IDEA',
  REDBOX_FUNDING = 'REDBOX_FUNDING',
  BLUEBOX_IDEA = 'BLUEBOX_IDEA',
  BLUEBOX_FUNDING = 'BLUEBOX_FUNDING',
  GOLDBOX = 'GOLDBOX',
  REJECTED = 'REJECTED',
  ALUMNI = 'ALUMNI',
  ARCHIVED = 'ARCHIVED'
}

export default ProjectTicketStatus;

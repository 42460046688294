/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable import/prefer-default-export */
import type {
  IProductConfigV2,
  IWorkflowNotificationV2,
  IWorkflowStatusV2,
  IWorkflowTransitionV2,
  IWorkflowV2,
  StatusKey
} from '@rready/sdk';
import type { ISpecialStatus, IWorkflowCompat } from '../IWorkflowCompat';

interface InnerStatus extends IWorkflowStatusV2 {
  name: StatusKey;
}

export class WorkflowV2 implements IWorkflowCompat {
  private workflow: IWorkflowV2;

  constructor(workflow: IWorkflowV2) {
    this.workflow = workflow;
  }

  get innerWorkflow(): IWorkflowV2 {
    console.warn('[DEPRECATED] InnerWorkflow');
    return this.workflow;
  }

  get statuses(): InnerStatus[] {
    return Object.entries(this.workflow.settings.status).map(
      ([key, status]) => ({
        name: key,
        ...status
      })
    );
  }

  private settingsProp(property: string) {
    const result: Record<string, string> = {};
    this.statuses
      .filter((x) => !!x.settings?.[property])
      .forEach((x) => {
        result[x.name] = x.settings?.[property];
      });
    return result;
  }

  private statusSequence(start: string, stack: string[], ignored: string[]) {
    const transitions = this.workflow.transitions;

    const current = transitions.find(
      (x) => x.transitionFrom === start && !ignored.includes(x.transitionTo!)
    );

    stack.push(start);
    if (current) {
      stack.concat(this.statusSequence(current.transitionTo!, stack, ignored));
    }
    return stack;
  }

  get product(): IProductConfigV2 | undefined {
    throw new Error(
      'IWorkflowCompat.product is invalid, use IProductCompat instead.'
    );
  }

  get notifications(): IWorkflowNotificationV2[] {
    return this.workflow.notifications ?? [];
  }

  /// COMPAT: activeWorkflowStatuses
  get activeWorkflowStatuses(): string[] {
    const start = this.workflow.settings.privateSaveStatus![0];
    const createStatus = this.workflow.settings.creationStatus;
    const deleteStatus = this.workflow.settings.deleteStatus![0];
    return this.statusSequence(start, [], [createStatus, deleteStatus]);
  }

  /// COMPAT: activeTransitions
  get activeTransitions(): any[] {
    return (this.workflow.transitions as IWorkflowTransitionV2[] as any) ?? [];
  }

  /// COMPAT: specialStatuses
  get specialStatuses(): ISpecialStatus {
    return {
      searchable: this.workflow.settings.searchable ?? [],
      assignedOverview:
        this.workflow.site?.app?.views?.['AssignedOverview']?.visibleStatus ??
        [],
      adminBoard:
        this.workflow.site?.admin?.views?.['OverviewBoard']?.visibleStatus ??
        [],
      portalOverview:
        this.workflow.site?.app?.views?.['PortalOverview']?.visibleStatus ?? [],
      showProgress: this.workflow.settings.showProgress ?? [],
      createStatus: [this.workflow.settings.creationStatus],
      privateSaveStatus: this.workflow.settings.privateSaveStatus ?? [],
      publicSaveStatus: this.workflow.settings.publicSaveStatus ?? [],
      finalStatus: this.workflow.settings.finalStatus ?? [],
      mailCoachingPhase: this.workflow.settings.mailCoachingPhase ?? [],
      invalidRestoreStatus: this.workflow.settings.invalidRestoreStatus ?? [],
      disableTimeline: this.workflow.settings.disableTimeline ?? [],
      rejectStatus: this.workflow.settings.rejectStatus ?? [],
      retireStatus: this.workflow.settings.retireStatus ?? [],
      deleteStatus: this.workflow.settings.deleteStatus ?? []
    };
  }

  /// COMPAT: videoPitches
  get videoPitches(): Record<string, string> {
    return this.settingsProp('videoPitches');
  }

  /// COMPAT: statusColours
  get statusColours(): Record<string, string> {
    return this.settingsProp('colour');
  }

  /// COMPAT: timelineGroup
  get timelineGroup(): Record<string, string> {
    const result: Record<string, string> = {};

    this.workflow.settings.phases?.forEach((phase) => {
      Object.entries(this.workflow.settings.status).forEach((entry) => {
        if (entry[1].phase === phase.name) {
          result[entry[0]] = phase.name;
        }
      });
    });
    return result;
  }
}






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import VideoPlayback from './VideoPlayback.vue';

@Component({
  name: 'VideoDisplay',
  components: { VideoPlayback }
})
export default class VideoDisplay extends Vue {
  @Prop({ default: '' }) readonly url!: string;

  @Prop({ default: null }) readonly videoObject!: FileModel;

  @Prop({ default: true }) readonly allowDefaultVideo!: boolean;

  defaultVideo = process.env.VUE_APP_DEFAULT_VIDEO;

  get playbackUrl(): string {
    const url = this.url || this.videoObject?.url;

    if (!url && this.allowDefaultVideo) {
      return this.defaultVideo || '';
    }

    return url || '';
  }
}

import { event, query } from 'vue-gtag';

export default {
  sendEvent(name: string, parameters = {}): void {
    event(name, parameters);
  },

  setUserId(userId: string): void {
    query('set' as any, 'user_id', userId as any);
  },

  setUserProperties(properties: unknown): void {
    query('set' as any, 'user_properties', properties as any);
  }
};

import type { RouteConfig } from 'vue-router';
import VueRouter from '@rready/common-utils/src/router';
import store from '../store';

const routes: RouteConfig[] = [
  {
    path: '/users',
    name: 'Users',
    children: [
      {
        path: 'all-users',
        name: 'AllUsers',
        meta: {
          selectedNavigationItem: 'Users'
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-users-all-users" */ '@rready/common-components/src/components/admin/users/GenericUserList.vue'
          )
      },
      {
        path: 'admins',
        name: 'Admins',
        meta: {
          selectedNavigationItem: 'Users'
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-users-admins" */ '@rready/common-components/src/components/admin/users/GenericUserList.vue'
          )
      }
    ],
    redirect: '/users/all-users'
  },
  {
    path: '/teams',
    name: 'Teams'
  },
  {
    path: '/teams/add/:type',
    name: 'CreateTeam',
    meta: {
      selectedNavigationItem: 'Teams'
    }
  },
  {
    path: '/teams/edit/:id',
    name: 'EditTeam',
    meta: {
      selectedNavigationItem: 'Teams'
    }
  },
  {
    path: '/topics',
    name: 'Topics'
  },
  {
    path: '/topics/add',
    name: 'CreateTopic',
    meta: {
      selectedNavigationItem: 'Topics'
    }
  },
  {
    path: '/topics/edit/:id',
    name: 'EditTopic',
    meta: {
      selectedNavigationItem: 'Topics'
    }
  },
  {
    path: '/tickets',
    name: 'Tickets'
  },
  {
    path: '/ticket/board',
    name: 'OverviewBoard',
    children: [
      {
        path: 'ticket/:id',
        name: 'TicketBoardSidePanel',
        component: () =>
          import(
            /* webpackChunkName: "admin-project-board" */ '../components/ticket/ticketSidePanel/TicketSidePanel.vue'
          )
      }
    ]
  },
  {
    path: '/notifications',
    name: 'Notifications'
  },
  {
    path: '/assessments',
    name: 'Assessments'
  },
  {
    path: '/coaching',
    name: 'Coaching',
    props: true,
    children: [
      {
        path: 'campaign/:campaignId?',
        name: 'CoachingSidePanel',
        component: () =>
          import(
            /* webpackChunkName: "admin-coaching-side-panel" */ '../views/CoachingSidePanel.vue'
          ),
        props: true
      },
      {
        path: 'campaign/:campaignId/sequence/:sequenceId?',
        name: 'CoachingSequenceSidePanel',
        component: () =>
          import(
            /* webpackChunkName: "admin-coaching-email-side-panel" */ '../views/CoachingSequenceSidePanel.vue'
          ),
        props: true
      }
    ]
  },
  {
    path: '/email-communication',
    name: 'EmailCommunication'
  },
  {
    path: '*',
    redirect: '/ticket/board',
    meta: {
      publicAccess: true
    }
  }
].map((route: RouteConfig) => ({
  component: () =>
    import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`),
  ...route
}));

export default VueRouter(routes, store, true);




































import { Action } from 'vuex-class';
import type { ActionMethod } from 'vuex';
import { Component, Prop, Watch } from 'vue-property-decorator';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import type Ticket from '@rready/common-utils/src/model/Ticket';
import fileIcon from '../../assets/files.svg';
import BaseMenu from '../widgets/BaseMenu.vue';
import BaseModal from '../widgets/BaseModal.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import BaseTicketFileUpload from '../widgets/BaseTicketFileUpload.vue';

@Component({
  name: 'BaseFilesUploadContainer',
  components: {
    BaseModal,
    BaseControlWrapper,
    BaseMenu,
    BaseAddButton,
    BaseTicketFileUpload
  }
})
export default class BaseFilesUploadContainer extends BaseFormControl {
  @Prop({ default: () => [] }) readonly files!: Array<any>;

  @Prop({ default: '' }) readonly category!: string;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Action removeFile!: ActionMethod;

  fileIcon = fileIcon;

  allFiles: Array<FileModel> = [];

  uploadedFiles = {};

  @Watch('ticket.files')
  updateAllFiles(): void {
    this.allFiles = [...this.ticket?.files].filter(
      (file: FileModel) => file.category === this.category
    );
  }

  created(): void {
    super.created();
    this.updateAllFiles();
  }

  openInputFiles(): void {
    document.getElementById('fileInputField')!.click();
  }

  filesChanged(file: FileModel): void {
    this.allFiles = [...this.allFiles, ...[file]];
  }

  removeUploadedFile(file: { id: string }): void {
    // If there is no file id, it means the upload was cancelled. So no need to remove the file
    if (file.id) {
      this.removeFile({ ticketId: this.ticket.canonicalId, fileId: file.id });
      this.allFiles = this.allFiles.filter((element) => element.id !== file.id);
    }
    this.$emit('removeFile', this.allFiles);
  }
}

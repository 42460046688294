


















































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter, Mutation } from 'vuex-class';
import type { ActionMethod } from 'vuex';
import { StorageUtils } from '@rready/common-utils/src/utils/storage';
import AppLogos from '@rready/common-components/src/components/widgets/AppLogos.vue';
import BaseSelect from '@rready/common-components/src/components/core/BaseSelect.vue';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import type Organization from '@rready/common-utils/src/model/Organization';

@Component({
  name: 'Header',
  components: {
    BaseSelect,
    AppLogos
  }
})
export default class Header extends Vue {
  @Getter getContextLoaded!: string;

  @Getter getCurrentProductId!: string;

  @Getter getOrganization?: Organization;

  @Getter drawerIsOpen?: boolean;

  @Getter isAuthenticated!: boolean;

  @Mutation setupCurrentProduct!: ActionMethod;

  @Action updateTranslationOverrides!: ActionMethod;

  @Action logout!: ActionMethod;

  @Action toggleNavigationalDrawer!: ActionMethod;

  get productId(): string {
    return this.getContextLoaded ? this.getCurrentProductId : '';
  }

  get productOptions(): MenuOption[] {
    if (!this.getContextLoaded) {
      return [];
    }
    return [
      ...(this.getOrganization?.enabledProducts || []).map((product) => ({
        title: this.$t(`productMenu.${product}`).toString(),
        value: product
      })),
      ...[
        {
          title: this.$t(`productMenu.portal`).toString(),
          value: 'portal'
        }
      ]
    ];
  }

  get menuOptions(): MenuOption[] {
    return [
      {
        title: this.$t('menu.featureRequest').toString(),
        onClick: (): void => {
          console.log('featureRequest');
        }
      },
      {
        title: this.$t('menu.bugReport').toString(),
        onClick: (): void => {
          console.log('bugReport');
        }
      },
      {
        title: this.$t('menu.logOut').toString(),
        onClick: (): void => this.logOut()
      }
    ];
  }

  switchSite(productId: string): void {
    if (productId === 'portal') {
      window.location.href = process.env.VUE_APP_PORTAL_URL as string;
      return;
    }
    StorageUtils.setProductId(productId);
    this.setupCurrentProduct(productId);
    this.updateTranslationOverrides();
    this.$router.replace('/');
    window.location.reload();
  }

  logOut(): void {
    this.logout();
  }

  toggleDrawer(): void {
    this.toggleNavigationalDrawer(!this.drawerIsOpen);
  }
}

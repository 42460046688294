import type { NavigationGuardNext, Route } from 'vue-router';
import type { Store } from 'vuex';

const featureGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  store: Store<any> // Vuex.Store
): Promise<void> => {
  const productConfig = store.getters.getContextLoaded
    ? store.getters.getProductConfig
    : undefined;

  if (!(productConfig?.getPageSettings(to.name!)?.enabled ?? true)) {
    next('/');
  }

  if (!!to.meta?.publicAccess || store.getters.isAuthenticated) {
    next();
  } else {
    store.dispatch('logout');
  }
};

export default featureGuard;

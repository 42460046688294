import { Type } from 'class-transformer';
import { TicketStatus } from './TicketStatus';

export default class TicketStats {
  @Type(() => Map)
  byStatus: Map<string, number>;

  total: number;

  constructor(
    byStatus: Map<string, number> = new Map<string, number>(),
    total = 0
  ) {
    this.byStatus = byStatus;
    this.total = total;
  }

  get totalNonArchived(): number {
    return this.total - (this.byStatus.get(TicketStatus.ARCHIVED) || 0);
  }
}

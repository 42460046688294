/* eslint-disable import/prefer-default-export */
import type { IWorkflowV2, IPortalConfigV2, IViewConfigV2 } from '@rready/sdk';
import type { IProductConfigCompat } from '../IProductConfigCompat';

export class ProductConfigV2 implements IProductConfigCompat {
  private _workflow: IWorkflowV2;

  constructor(workflow: IWorkflowV2) {
    this._workflow = workflow;
    this.label = workflow.product?.label ?? '';
    this.ticketType = workflow.product?.ticketType ?? '';
    this.taskType = workflow.product?.taskType ?? '';
  }

  label: string;

  taskType: string;

  ticketType: string;

  get defaultCardType(): string {
    throw new Error('defaultCardType');
  }

  get assignmentsFilterPreset(): string[] {
    throw new Error('assignmentsFilterPreset');
  }

  get defaultAssignmentsFilterPreset(): string {
    throw new Error('defaultAssignmentsFilterPreset');
  }

  public get product() {
    console.warn(`[DEPRECATED] ProductConfig.product`);
    return this._workflow.product;
  }

  public get workflow(): any {
    console.warn(`[DEPRECATED] ProductConfig.workflow`);
    return this._workflow;
  }

  getPortalConfig(): IPortalConfigV2 | undefined {
    return (this._workflow?.site as any)[
      process.env.VUE_APP_APP_NAME!
    ] as IPortalConfigV2;
  }

  getPages(): Record<string, IViewConfigV2> {
    return this.getPortalConfig()?.views ?? {};
  }

  getPageSettings(pageName: string): IViewConfigV2 | undefined {
    return this.getPages()[pageName];
  }
}






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type Team from '@rready/common-utils/src/model/Team';
import type { DropDownOption } from '@rready/common-utils/src/types/DropDownOption';
import BaseAutoComplete from '../core/BaseAutoComplete.vue';

@Component({
  name: 'TeamSelector',
  components: {
    BaseAutoComplete
  }
})
export default class TeamSelector extends Vue {
  @Prop({ default: () => [] }) readonly userTeams!: Array<Team>;

  @Prop({ default: () => [] }) readonly orgTeams!: Array<Team>;

  get options(): DropDownOption[] {
    (this.$refs[this.$attrs.ref || 'selectTeam'] as any)?.clearCachedItems();
    const options = [];
    if (this.userTeams.length) {
      options.push(
        { header: this.$t('page.teams.myTeams') },
        ...(this.userTeams as Array<any>)
      );
    }

    if (this.orgTeams.length > this.userTeams.length) {
      options.push(
        { header: this.$t('page.teams.allTeams') },
        ...(this.orgTeams as Array<any>)
      );
    }
    return options;
  }
}

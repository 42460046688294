import Vue from 'vue';
import Vuetify, { VCard, VBtn } from 'vuetify/lib';
import '@fortawesome/fontawesome-pro/css/all.css';

Vue.use(Vuetify, {
  components: {
    VCard,
    VBtn
  }
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2b94fc',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
});

import { getCurrentAuth } from './SecurityService';

const config = {
  leanAssessmentUrl: process.env.VUE_APP_ASSESSMENT_HOST
};

export default function getAssessmentUrl(): string | null {
  const auth = getCurrentAuth();
  if (!auth || !auth.token) {
    return null;
  }
  return `${config.leanAssessmentUrl}/admin?layout=plain&sessionId=${auth.token}`;
}

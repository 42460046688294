var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-control-container"},[_c('base-control-wrapper',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){return [_c('v-autocomplete',_vm._b({ref:_vm.elementId,staticClass:"base-control-container-auto-complete",attrs:{"id":_vm.elementId,"disabled":_vm.disabled,"height":_vm.$attrs.height || 50,"items":_vm.resolvedItems,"loading":_vm.loading || _vm.resolvingItems,"multiple":_vm.$attrs.multiple,"placeholder":_vm.placeholderText,"prepend-inner-icon":_vm.$attrs['prepend-inner-icon'] || 'fal fa-search',"readonly":_vm.disabled,"search-input":_vm.searchString,"value":_vm.$attrs.value,"appendIcon":"mdi-chevron-down","cache-items":"","dense":"","flat":"","hide-details":"","solo":""},on:{"update:searchInput":function($event){_vm.searchString=$event},"update:search-input":function($event){_vm.searchString=$event},"blur":function($event){return _vm.setMultiSelectionText(true)},"change":function (e) { return _vm.$emit('input', e); },"focus":function($event){return _vm.setMultiSelectionText(false)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("item",function(){return [(_vm.$attrs.multiple)?_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(attrs.inputValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")])],1):_vm._e(),_c('v-list-item-content',_vm._b({attrs:{"data-recording-sensitive":""}},'v-list-item-content',on,false),[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.getDisplayText(item, _vm.$attrs['item-text']))}})],1)]},{"item":item,"on":on})]}},(_vm.$attrs.multiple)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0 && _vm.showMultiSelectionText)?_c('span',[_vm._v(" "+_vm._s(((_vm.$attrs.value.length) + " selected"))+" ")]):_vm._e()]}}:(_vm.$scopedSlots.selection)?{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var index = ref.index;
var select = ref.select;
var selected = ref.selected;
var disabled = ref.disabled;
return [_vm._t("selection",null,{"disabled":disabled,"index":index,"item":item,"parent":parent,"select":select,"selected":selected})]}}:null,{key:"no-data",fn:function(){return [_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t('common.noDataAvailable'))+" ")])]},proxy:true},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}],null,true)},'v-autocomplete',_vm.$attrs,false))]}},{key:"errors",fn:function(ref){
var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable import/prefer-default-export */
export class StorageUtils {
  private static KEY_PRODUCT_ID = 'currentProductId';

  private static KEY_USER = 'user';

  static setProductId(productId: string): void {
    localStorage.setItem(StorageUtils.KEY_PRODUCT_ID, productId);
  }

  static getProductId(): string | undefined {
    return localStorage.getItem(StorageUtils.KEY_PRODUCT_ID) ?? undefined;
  }

  static setUser(user: string): void {
    localStorage.setItem(StorageUtils.KEY_USER, user);
  }

  static getUser(): string | undefined {
    return localStorage.getItem(StorageUtils.KEY_USER) ?? undefined;
  }

  static clear(): void {
    return localStorage.clear();
  }
}

import { instanceToInstance } from 'class-transformer';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type {
  ITransitionDialogSettings,
  IWorkflowTransitionV2
} from '@rready/sdk';
import TicketService from '../../services/TicketService';
import type { TicketState } from '../../types/TicketState';
import TicketStats from '../../types/TicketStats';
import TicketSearchParams from '../../types/TicketSearchParams';
import TicketStatsParams from '../../types/TicketStatsParams';
import type Ticket from '../../model/Ticket';
import type { TicketStatusType } from '../../types/TicketStatus';
import {
  cancelMultiUpload,
  convertVideo,
  dataUrlToFile,
  processMultiUpload,
  removeAttachment,
  startMultiUpload,
  stopMultiUpload
} from '../../services/FileUploadService';
import type { StopMultiUploadResponse } from '../../types/MultiUploadResponse';
import type { UploadState } from '../../types/UploadState';
import type Task from '../../model/Task';
import type TaskUpdateParams from '../../types/TaskUpdateParams';
import TaskStatus from '../../types/TaskStatus';
import type { VUFile } from '../../types/VUFile';
import type { UploadDataUrlPayload } from '../../types/UploadDataUrlPayload';
import type { UploadResponse } from '../../types/MultiUploadRequest';
import i18n from '../../i18n';

const state: TicketState = {
  tickets: [],
  ticketsByCanonicalId: new Map<string, Ticket>(),
  ticketStats: new TicketStats(),
  assignedTickets: new Map<string, Array<Ticket>>(),
  allTicketsByStatus: new Map<string, Array<Ticket>>()
};

const getters: GetterTree<TicketState, any> = {
  tickets: (st) => st.tickets,
  ticketsByCanonicalId: (st) => st.ticketsByCanonicalId,
  ticketStats: (st) => st.ticketStats,
  assignedTickets: (st) => st.assignedTickets,
  getAssignedTicketsByStatus: (st) => (status: string) =>
    instanceToInstance(st.assignedTickets.get(status)) || [],
  getAllTicketsByStatus: (st) => (status: string) =>
    instanceToInstance(st.allTicketsByStatus.get(status)) || []
};

const refreshTicketsByCanId = () => {
  // Updates the ticketsByCanonicalId with a new instance of it. So all the components using
  // @Getter ticketsByCanonicalId gets updated. Setting a new item by key doesn't trigger the update
  // of the component.
  state.ticketsByCanonicalId = instanceToInstance(state.ticketsByCanonicalId);
};

const updateTicketsByCanId = (source: Array<Ticket>) => {
  source.forEach((t: Ticket) =>
    state.ticketsByCanonicalId.set(t.canonicalId!, t)
  );
  refreshTicketsByCanId();
};

const updateTicketsByStatus = (source: Array<Ticket>) => {
  source.forEach((ticket: Ticket): void => {
    const oldTickets = (
      state.allTicketsByStatus.get(ticket.status!) || []
    ).filter((oldTicket: Ticket): boolean => oldTicket.id !== ticket.id);
    state.allTicketsByStatus.set(ticket.status!, [...oldTickets, ticket]);
  });

  state.allTicketsByStatus = instanceToInstance(state.allTicketsByStatus);
};

const getFieldValue = (field: string, container: any) =>
  field.split('.').reduce((result, key) => result[key], container);

const changeTicketCollection = (
  st: TicketState,
  payload: {
    ticket: Ticket;
    fromStatus: string;
    toStatus: string;
    toIndex: number;
  },
  stateType: 'assignedTickets' | 'allTicketsByStatus'
) => {
  let fromTickets = st[stateType].get(payload.fromStatus) || [];
  fromTickets = fromTickets.filter((t) => t.id !== payload.ticket.id);
  const toTickets = st[stateType].get(payload.toStatus) || [];
  toTickets.splice(payload.toIndex, 0, payload.ticket);

  st[stateType].set(payload.fromStatus, fromTickets);
  st[stateType].set(payload.toStatus, toTickets);
  st[stateType] = instanceToInstance(st[stateType]);
};

const mutations: MutationTree<TicketState> = {
  setTickets(st: TicketState, tickets: Array<Ticket>) {
    st.tickets = tickets;
    updateTicketsByCanId(st.tickets);
    updateTicketsByStatus(st.tickets);
  },
  setTicketById(st: TicketState, ticketById: { id: string; ticket: Ticket }) {
    if (!st.ticketsByCanonicalId.has(ticketById.id)) {
      st.tickets = [ticketById.ticket, ...(st.tickets || [])];
      updateTicketsByCanId(st.tickets);
    } else {
      updateTicketsByCanId([ticketById.ticket]);
      const index = st.tickets
        ? st.tickets.findIndex((_: Ticket) => _.canonicalId === ticketById.id)
        : -1;
      if (index >= 0) {
        st.tickets.splice(index, 1, ticketById.ticket);
        st.tickets = instanceToInstance(st.tickets);
      }
    }
    // Update the assignments source
    let tkts = st.assignedTickets.get(ticketById.ticket.status!);
    if (tkts && tkts.length) {
      tkts = tkts.map((_) =>
        _.id === ticketById.ticket.id ? ticketById.ticket : _
      );
      st.assignedTickets = instanceToInstance(
        st.assignedTickets.set(ticketById.ticket.status!, tkts)
      );
    }
  },
  setTicketStats(st: TicketState, stats: TicketStats) {
    st.ticketStats = instanceToInstance(stats);
  },
  setAssignedTicketsByStatus(
    st: TicketState,
    payload: { status: Array<string>; tickets: Array<Ticket> }
  ) {
    const sts = payload.status.join(',');
    let newTickets = payload.tickets.map((t) => {
      st.ticketsByCanonicalId.set(t.canonicalId!, t);
      return st.ticketsByCanonicalId.get(t.canonicalId!)!;
    });
    const oldTickets = st.assignedTickets.has(sts)
      ? st.assignedTickets.get(sts)!
      : [];

    // filter out duplicate tickets
    newTickets = newTickets.filter(
      (newTicket) =>
        !oldTickets.some((oldTicket) => oldTicket.id === newTicket.id)
    );
    const allTickets = oldTickets.concat(newTickets);

    st.assignedTickets = instanceToInstance(
      st.assignedTickets.set(sts, allTickets)
    );
    refreshTicketsByCanId();
  },
  resetAssignedTicketsByStatus(
    st: TicketState,
    payload: { status: Array<string> }
  ) {
    const sts = payload.status.join(',');
    st.assignedTickets.set(sts, []);
  },
  changeAssignedTicketStatus(
    st: TicketState,
    payload: {
      ticket: Ticket;
      fromStatus: string;
      toStatus: string;
      toIndex: number;
    }
  ) {
    changeTicketCollection(st, payload, 'assignedTickets');
  },
  changeAllTicketsByStatus(
    st: TicketState,
    payload: {
      ticket: Ticket;
      fromStatus: string;
      toStatus: string;
      toIndex: number;
    }
  ) {
    changeTicketCollection(st, payload, 'allTicketsByStatus');
  }
};

const actions: ActionTree<TicketState, any> = {
  async createTicket(
    { dispatch, rootGetters },
    newTicket: Ticket
  ): Promise<Ticket> {
    const product = rootGetters.getCurrentProductId;
    const ticket = await TicketService.createTicket(newTicket, product);
    dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(ticket);
  },

  async setValidationErrorMessage(
    { commit, dispatch },
    transitionStatus: {
      validation: { result: boolean; reason?: string; args?: Array<string> };
      fromStatus: string;
      toStatus: string;
    }
  ) {
    const message = await dispatch('getErrorMessage', transitionStatus);
    commit('setAlertMessage', { message });
  },

  getErrorMessage(
    unused,
    transitionStatus: {
      validation: { result: boolean; reason?: string; args?: Array<string> };
      fromStatus: string;
      toStatus: string;
    }
  ): string {
    const { validation, toStatus, fromStatus } = transitionStatus;
    switch (validation.reason) {
      case 'invalid.transition': {
        return i18n
          .t('error.invalidTransition', {
            fromStatus,
            toStatus
          })
          .toString();
      }
      case 'invalid.fields': {
        const fields = validation.args?.join(', ') || '';
        return i18n.t('error.invalidFields', { fields }).toString();
      }
      default:
        return '';
    }
  },

  async getAllTickets(
    { commit, dispatch, rootGetters },
    payload: TicketSearchParams
  ): Promise<Array<Ticket>> {
    const searchParams: TicketSearchParams =
      payload ||
      new TicketSearchParams(
        rootGetters.getProductConfig.ticketType,
        rootGetters.getCurrentProductId
      );
    const tickets: Array<Ticket> = await TicketService.getAllTickets(
      searchParams
    );
    commit('setTickets', tickets);
    await dispatch('fetchInformationForTickets', { tickets, tasks: true });
    return Promise.resolve(tickets);
  },

  async getAssignedTickets(
    { commit, dispatch, rootGetters },
    payload?: TicketSearchParams
  ): Promise<Array<Ticket>> {
    const searchBy =
      payload ||
      new TicketSearchParams(
        rootGetters.getProductConfig.ticketType,
        rootGetters.getCurrentProductId
      );

    if (!searchBy.page) {
      commit('resetAssignedTicketsByStatus', {
        status: payload?.status || ['ALL']
      });
    }
    const tickets = await TicketService.getAssignedTickets(searchBy);
    commit('setAssignedTicketsByStatus', {
      status: payload?.status || ['ALL'],
      tickets
    });
    await dispatch('fetchInformationForTickets', { tickets, tasks: true });
    return Promise.resolve(tickets);
  },

  async getTicketById(
    { dispatch, state: ticketState, rootGetters },
    canonicalId: string
  ): Promise<Ticket> {
    if (ticketState.ticketsByCanonicalId.has(canonicalId)) {
      return Promise.resolve(
        instanceToInstance(
          ticketState.ticketsByCanonicalId.get(canonicalId) as Ticket
        )
      );
    }
    const product = rootGetters.getCurrentProductId;
    const ticket = await TicketService.getTicketById(canonicalId, product);
    dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(instanceToInstance(ticket));
  },

  async getTicketStats(
    { commit, getters: stateGetters, rootGetters },
    payload?: TicketStatsParams
  ): Promise<TicketStats> {
    const ticketType = rootGetters.getProductConfig!.ticketType!;
    const ticketStatsParams: TicketStatsParams =
      payload || new TicketStatsParams(ticketType);
    if (!ticketStatsParams.type) {
      ticketStatsParams.type = stateGetters.getProductConfig.ticketType;
    }
    const ticketStats = await TicketService.getTicketStats(ticketStatsParams);
    commit('setTicketStats', ticketStats);
    return Promise.resolve(ticketStats);
  },

  async setTicketById(
    { commit, dispatch },
    payload: { id: string; ticket: Ticket }
  ): Promise<Ticket> {
    commit('setTicketById', {
      id: payload.id,
      ticket: payload.ticket
    });

    await dispatch('fetchInformationForTickets', {
      tickets: [payload.ticket],
      tasks: true
    });
    return Promise.resolve(payload.ticket);
  },

  async fetchInformationForTickets(
    { dispatch },
    payload: { tickets: Array<Ticket>; tasks: boolean } = {
      tickets: [],
      tasks: true
    }
  ) {
    console.warn('OLD: fetchInformationForTickets');
    const userMap = new Map<string, boolean>();
    const cIds: Array<string> = [];
    payload.tickets.forEach((ticket: Ticket) => {
      userMap.set(ticket.creator!, true);
      userMap.set(ticket.assignee!, true);
      cIds.push(ticket.canonicalId!);
      if (payload.tasks) {
        ticket.tasks.forEach((task: Task) => {
          userMap.set(task.assignee!, true);
        });
      }
    });
    await dispatch('fetchUsersByBatch', Array.from(userMap.keys()), {
      root: true
    });
    return Promise.resolve(true);
  },

  async updateTicket(
    { dispatch, rootGetters },
    updatedTicket: Ticket
  ): Promise<Ticket> {
    const product = rootGetters.getCurrentProductId;
    const ticket = await TicketService.updateTicket(updatedTicket, product);
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(ticket);
  },

  async updateTasks(
    { dispatch, rootGetters },
    payload: { parent: Ticket; updates: TaskUpdateParams }
  ): Promise<Ticket> {
    const product = rootGetters.getCurrentProductId;
    const ticket = payload.parent;
    const { added, updated, removed } = payload.updates;

    // If no changes made to tasks, don't proceed
    if (!added.length && !updated.length && !removed.length) {
      return Promise.resolve(ticket);
    }
    const newOnes = await Promise.all(
      added.map((t) =>
        dispatch('createTicket', t).then((nT) => ({ id: t.id, ticket: nT }))
      )
    );

    const oldOnes = await Promise.all(
      updated.map((t) =>
        dispatch('updateTicket', t).then((nT) => ({ id: t.id, ticket: nT }))
      )
    );

    const updates = [...newOnes, ...oldOnes];
    ticket.tasks = ticket.tasks.map(
      (t) => updates.find((u) => u.id === t.id)?.ticket || t
    );
    ticket.tasks = ticket.tasks.filter(
      (t) => !removed.find((u) => u.id === t.id)
    );
    const updatedTicket = await TicketService.updateTasks(
      ticket,
      ticket.tasks as Array<Task>,
      product
    );
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket: updatedTicket
    });
    return Promise.resolve(ticket);
  },

  async updateTaskCompletion(
    { commit, dispatch },
    payload: { parent: Ticket; complete: boolean }
  ): Promise<Ticket> {
    const nextStatus = payload.complete
      ? TaskStatus.COMPLETED
      : TaskStatus.DRAFT;
    const ticket = payload.parent;
    if (ticket.status === nextStatus) {
      return Promise.resolve(payload.parent);
    }
    commit('setAlertMessage', { message: i18n.t('page.tasks.completeTask') });

    await dispatch('updateTicketStatus', {
      ticket,
      fromStatus: ticket.status,
      toStatus: nextStatus
    });
    ticket.status = nextStatus;
    return ticket;
  },

  async updateTicketStatus(
    { commit, dispatch, rootGetters },
    payload: {
      ticket: Ticket;
      fromStatus: string;
      toStatus: string;
      newIndex: number;
    }
  ): Promise<Ticket> {
    const product = rootGetters.getCurrentProductId;
    const ticket: Ticket = await TicketService.updateTicketStatus(
      payload.ticket.canonicalId!,
      payload.ticket.type!,
      payload.toStatus,
      product
    );
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    commit('changeAssignedTicketStatus', {
      ticket,
      fromStatus: payload.fromStatus,
      toStatus: payload.toStatus,
      toIndex: payload.newIndex || 0
    });
    commit('changeAllTicketsByStatus', {
      ticket,
      fromStatus: payload.fromStatus,
      toStatus: payload.toStatus,
      toIndex: payload.newIndex || 0
    });
    return Promise.resolve(ticket);
  },

  async submitNewTicket(
    { dispatch },
    { ticket, status }: { ticket: Ticket; status: TicketStatusType }
  ): Promise<Ticket> {
    const newTicket: Ticket = await dispatch('createTicket', ticket);
    return dispatch('updateTicketStatus', {
      cId: newTicket.canonicalId,
      status
    });
  },

  validateStatusTransition(
    { rootGetters },
    payload: { ticket: Ticket; nextStatus: string }
  ): {
    result: boolean;
    reason?: string;
    args?: string[];
    transitionDialog?: ITransitionDialogSettings;
  } {
    const transition: IWorkflowTransitionV2 =
      rootGetters.getProductWorkflow.activeTransitions.find(
        (_: IWorkflowTransitionV2) =>
          (_.transitionFrom === payload.ticket.status &&
            _.transitionTo === payload.nextStatus) ||
          (_.transitionTo === payload.ticket.status &&
            _.transitionFrom === payload.nextStatus &&
            _.bidirectional)
      );
    if (!transition) {
      return { result: false, reason: 'invalid.transition' };
    }
    const invalidFields =
      transition.requiredFields?.filter(
        (field) => !getFieldValue(field, payload.ticket)
      ) || [];
    if (invalidFields.length) {
      return {
        result: false,
        reason: 'invalid.fields',
        args: invalidFields
      };
    }
    let transitionDialog;
    if (
      transition.transitionDialog?.active &&
      transition.transitionFrom === payload.ticket.status &&
      transition.transitionTo === payload.nextStatus
    ) {
      transitionDialog = transition.transitionDialog;
    }
    return {
      result: true,
      transitionDialog
    };
  },

  async uploadDataUrl(
    { dispatch },
    { dataUrl, fileName, objectId, category }: UploadDataUrlPayload
  ): Promise<UploadResponse> {
    const convertedFile = await dataUrlToFile(dataUrl, fileName);

    const file = {
      name: fileName,
      file: convertedFile,
      type: convertedFile.type,
      size: convertedFile.size
    } as unknown as VUFile;

    return dispatch('uploadLargeFile', {
      file,
      objectId,
      category,
      inputId: objectId
    });
  },

  async uploadLargeFile(
    { commit },
    { inputId, objectId, file, category }
  ): Promise<UploadResponse> {
    const { type: contentType, name: originalFilename, size } = file;
    const { uploadId, key } = await startMultiUpload(objectId, {
      contentType,
      tags: {
        originalFilename,
        size,
        contentType
      }
    });
    commit('initUploadState', {
      inputId,
      objectId,
      file,
      uploadId,
      key,
      parts: [],
      uploadProgress: 0
    });
    const callBack = (uploadState: UploadState) =>
      commit('setUploadProgress', uploadState);
    try {
      const parts: string[] = await processMultiUpload(
        inputId,
        uploadId,
        file,
        key,
        callBack
      );

      const response: StopMultiUploadResponse = await stopMultiUpload(
        objectId,
        {
          category,
          contentType,
          originalFilename,
          uploadId,
          parts,
          key
        }
      );
      commit('removeUploadState', inputId);

      return {
        success: true,
        mediaObject: {
          id: response.file.id,
          url: response.file.url,
          filename: response.file.filename,
          contentSize: response.file.contentSize!,
          internal: response.file.internal,
          category: response.file.category,
          meta: response.file.meta,
          fallbackLocations: response.file.fallbackLocations
        }
      };
    } catch (e) {
      // Devour error because the user cancelled the upload
      return {
        success: false
      };
    }
  },

  cancelFileUpload({ commit, rootGetters }, { inputId }): void {
    const { uploadId, key }: UploadState =
      rootGetters.uploadStateByInputId.get(inputId);

    commit('removeUploadState', inputId);
    cancelMultiUpload({ uploadId, key });
  },

  removeFile(unused, { ticketId, fileId }): Promise<void> {
    return removeAttachment(ticketId, fileId);
  },

  convertVideo(unused, payload): void {
    convertVideo(payload.objectId, payload.fieldName);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};








































































import { Component, Prop } from 'vue-property-decorator';
import InlineSvg from 'vue-inline-svg';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import calendar from '../../assets/calendar-icon.svg';
import calendarDisabled from '../../assets/calendar-icon-disabled.svg';

@Component({
  name: 'BaseDatePicker',
  inheritAttrs: false,
  components: {
    BaseControlWrapper,
    InlineSvg
  }
})
export default class BaseDatePicker extends BaseFormControl {
  @Prop({ default: '' }) readonly selectedDate!: string;

  @Prop({ default: 'month' }) readonly calendarType!: string;

  @Prop({ default: null }) readonly minDate!: string;

  @Prop({ default: true }) readonly setDefaultDate!: boolean;

  date = '';

  menu = false;

  calendarIcon = calendar;

  calendarIconDisabled = calendarDisabled;

  get minDateCalendar(): string {
    return this.minDate || new Date().toISOString().substr(0, 7);
  }

  get formattedDate(): string {
    return this.date
      ? this.$date(this.date, 'DD.M.YYYY').endOf('day').format('DD.M.YYYY')
      : '';
  }

  created(): void {
    super.created();
    this.date = this.selectedDate;
  }

  setDate(inputDate: string): void {
    this.menu = false;
    this.$emit('input', inputDate);
  }
}

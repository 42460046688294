export default class TopicMetaData {
  constructor(
    displayName: string | null = null,
    color: string | null = null,
    description: string | null = null,
    imageUrl: string | null = null,
    imageName: string | null = null,
    imageSize: string | null = null,
    imageId: string | null = null,
    imageType: string | null = null
  ) {
    this.displayName = displayName;

    this.color = color;

    this.description = description;

    this.imageUrl = imageUrl;

    this.imageName = imageName;

    this.imageSize = imageSize;

    this.imageId = imageId;

    this.imageType = imageType;
  }

  displayName: string | null;

  color: string | null;

  description: string | null;

  imageUrl: string | null;

  imageName: string | null;

  imageSize: string | null;

  imageId: string | null;

  imageType: string | null;
}

import SortDirection from './SortDirection';

export default class TicketSearchParams {
  title: string | null = null;

  assignee: string | null = null;

  creator: Array<string>;

  delegate: Array<string>;

  filterPreset: string | null = null;

  status: Array<string>;

  type: string;

  product: string;

  page: number;

  size: number;

  sort: SortDirection;

  constructor(type: string, product: string) {
    this.creator = [];
    this.delegate = [];
    this.status = [];

    this.product = product;
    this.type = type;
    this.page = 0;
    this.size = 2000;
    this.sort = SortDirection.ASC;
  }

  buildUrl(): string {
    const params = [];
    // Set search params for title
    if (this.title && this.title.length) {
      params.push(`title=${this.title}`);
    }

    // Set search params for assignee
    if (this.assignee && this.assignee.length) {
      params.push(`assignee=${this.assignee}`);
    }

    // Set search params for creator
    if (this.creator && this.creator.length) {
      params.push(this.creator.map((_) => `creator=${_}`).join('&'));
    }

    // Set search params for delegate
    if (this.delegate && this.delegate.length) {
      params.push(this.delegate.map((_) => `delegate=${_}`).join('&'));
    }

    // Set search params for filterPreset
    if (this.filterPreset) {
      params.push(`filterPreset=${this.filterPreset}`);
    }

    // Set search params for status
    if (this.status && this.status.length) {
      params.push(this.status.map((_) => `status=${_}`).join('&'));
    }

    // Set search params for type
    params.push(`type=${this.type}`);

    // Set search params for page
    params.push(`page=${this.page}`);

    // Set search params for size
    params.push(`size=${this.size}`);

    // Set search params for sort
    params.push(`sort=${this.sort}`);

    return params.join('&');
  }

  nextPage(): TicketSearchParams {
    this.page += 1;
    return this;
  }
}



























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { get } from 'lodash';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  inheritAttrs: false,
  name: 'BaseAutoComplete',
  components: {
    BaseControlWrapper
  }
})
export default class BaseAutoComplete extends BaseFormControl {
  @Prop({ default: () => [] }) items!: Promise<unknown[]> | unknown[];

  resolvedItems: unknown[] = [];

  showMultiSelectionText = true;

  searchString: string | null = null;

  resolvingItems = true;

  @Watch('searchString')
  onSearch(newValue: string): void {
    this.$emit('searchUpdated', newValue);
  }

  async created(): Promise<void> {
    super.created();
    this.resolveItems();
  }

  @Watch('items')
  async resolveItems(): Promise<void> {
    this.resolvingItems = true;
    this.resolvedItems = await this.items;
    this.resolvingItems = false;
  }

  endIntersect(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
    isIntersecting: boolean
  ) {
    if (isIntersecting) {
      this.$emit('loadMore', this.searchString);
    }
  }

  getDisplayText(
    item: Record<string, string> | string,
    itemText: string
  ): string {
    if (typeof item === 'object') {
      return get(item, itemText || 'text');
    }
    return item;
  }

  setMultiSelectionText(value: boolean): void {
    if (value) {
      this.clearSearchString();
    }
    this.showMultiSelectionText = value;
  }

  clearCachedItems(): void {
    const autocomplete = this.$refs[this.elementId] as any;
    if (autocomplete) {
      autocomplete.cachedItems = [];
    }
  }

  private clearSearchString(): void {
    this.searchString = '';
  }
}

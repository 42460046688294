







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import { debounce } from 'lodash';
import type User from '@rready/common-utils/src/model/User';
import UserSearchParams from '@rready/common-utils/src/types/UserSearchParams';
import BaseAutoComplete from '../core/BaseAutoComplete.vue';
import BaseUserAvatar from './BaseUserAvatar.vue';

@Component({
  name: 'UserSelector',
  components: {
    BaseAutoComplete,
    BaseUserAvatar
  }
})
export default class UserSelector extends Vue {
  @Action fetchUsers!: ActionMethod;

  users: Array<User> = [];

  loading = false;

  handleSearch = debounce(
    (searchString: string) => this.search(searchString),
    300
  );

  get activeUsers(): Array<User> {
    return this.users.filter(
      (user) =>
        user.firstName !== null && user.lastName !== null && user.enabled
    );
  }

  created(): void {
    this.fetch(20);
  }

  fullName(item: User): string {
    return `${item.firstName} ${item.lastName}`;
  }

  async fetch(size: number, name: string | null = null): Promise<void> {
    const searchParams = new UserSearchParams();
    searchParams.size = size;
    if (name !== null) searchParams.name = name.toLowerCase();

    this.loading = true;
    this.users = await this.fetchUsers({ params: searchParams });
    this.loading = false;
  }

  async search(searchString: string | null): Promise<void> {
    this.fetch(100, searchString);
  }
}

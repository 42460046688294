import { render, staticRenderFns } from "./BaseMenu.vue?vue&type=template&id=7dd88386&"
import script from "./BaseMenu.vue?vue&type=script&lang=ts&"
export * from "./BaseMenu.vue?vue&type=script&lang=ts&"
import style0 from "./BaseMenu.vue?vue&type=style&index=0&id=7dd88386&prod&lang=scss&"
import style1 from "./BaseMenu.vue?vue&type=style&index=1&id=7dd88386&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu})

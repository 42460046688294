








import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import type { IProductCustomization } from '@rready/sdk';
import type Organization from '@rready/common-utils/src/model/Organization';

@Component({
  name: 'AppLogos'
})
export default class AppLogos extends Vue {
  @Getter getCurrentProductId!: string;

  @Getter getOrganization!: Organization;

  @Getter getProductCustomization!: IProductCustomization;

  get organizationLogo(): string {
    return this.getOrganization.logo ?? '';
  }

  get productName(): string {
    return this.getProductCustomization?.name ?? '';
  }

  get productLogo(): string {
    return (
      this.getProductCustomization?.logo ??
      `/img/logo_${this.getCurrentProductId}.svg`
    );
  }
}























































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { instanceToInstance } from 'class-transformer';
import type BaseTableColumn from '@rready/common-utils/src/types/BaseTableColumn';
import type Team from '@rready/common-utils/src/model/Team';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import BaseMenu from '../widgets/BaseMenu.vue';
import BaseModal from '../widgets/BaseModal.vue';
import PrimaryButton from '../widgets/PrimaryButton.vue';
import SecondaryButton from '../widgets/SecondaryButton.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseFormControl from '../../helpers/BaseFormControl';

@Component({
  name: 'BaseTable',
  components: {
    BaseModal,
    PrimaryButton,
    SecondaryButton,
    BaseMenu,
    BaseAddButton
  }
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-confusing-arrow */
export default class BaseTable extends BaseFormControl {
  @Prop({ default: () => [] }) readonly columns!: Array<BaseTableColumn>;

  @Prop({ default: () => [] }) readonly rows!: Array<any>;

  @Prop({ default: true }) readonly canAdd!: boolean;

  @Prop({ default: true }) readonly canEdit!: boolean;

  @Prop({ default: true }) readonly canDelete!: boolean;

  @Getter allTeamsById!: (id: string) => Team;

  selectedItem: any;

  showEditDialog = false;

  showDeleteDialog = false;

  customRows: Array<any> = [];

  get rowOptions(): Array<MenuOption> {
    const options: Array<MenuOption> = [];
    if (this.canEdit) {
      options.push({
        title: this.$t('menu.edit').toString(),
        value: 'edit'
      });
    }
    if (this.canDelete) {
      options.push({
        title: this.$t('menu.delete').toString(),
        value: 'delete'
      });
    }
    return options;
  }

  get editableColumns(): Array<BaseTableColumn> {
    return this.columns.filter((c) => c.value?.indexOf('actions') < 0);
  }

  created(): void {
    super.created();
    this.init();
  }

  @Watch('rows')
  init() {
    this.customRows = instanceToInstance(this.rows);
  }

  attemptAddingNewItem() {
    this.selectedItem = {};
    this.showEditDialog = true;
  }

  attemptUpdate(item: any): void {
    this.selectedItem = instanceToInstance(item);
    this.showEditDialog = true;
  }

  async save() {
    if (!(await this.validateEditors())) return;
    const item = this.selectedItem;
    if (!item.id) {
      this.customRows.push({ id: Date.now(), ...item });
    } else {
      this.customRows = this.customRows.map((r) =>
        r.id === item.id ? item : r
      );
    }
    this.$emit('input', this.customRows);
    this.reset();
  }

  attemptDelete(item: any): void {
    this.selectedItem = instanceToInstance(item);
    this.showDeleteDialog = true;
  }

  deleteConfirm(): void {
    this.customRows = this.customRows.filter(
      (r) => r.id !== this.selectedItem.id
    );
    this.$emit('input', this.customRows);
    this.reset();
  }

  setDynamicFieldValue(input: any, header: BaseTableColumn): void {
    this.selectedItem[header.value] =
      header.type === 'number' ? Number(input) : input;
  }

  getDisplayDataByColType(item: any, header: BaseTableColumn) {
    const value = item[header.value];
    return value?.label || value;
  }

  reset() {
    this.showEditDialog = false;
    this.showDeleteDialog = false;
    this.selectedItem = null;
  }

  onMenuOptionClick(option: MenuOption, item: any): void {
    switch (option.value) {
      case 'edit': {
        this.attemptUpdate(item);
        break;
      }
      case 'delete': {
        this.attemptDelete(item);
        break;
      }
      default: {
        break; /* Otherwise ESLint complains */
      }
    }
  }

  validateEditors(): Promise<any> {
    const component: any = this.$refs.dynamicFields;
    return component.validate();
  }
}

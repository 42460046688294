import type { RawLocation } from 'vue-router';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
// Override the push function to prevent the NavigationDuplicated error
VueRouter.prototype.push = async function push(
  location: RawLocation
): Promise<any> {
  let result;
  try {
    result = await originalPush.call(this, location);
  } catch (error: unknown) {
    const { name, type } = error as { name: string; type: number };
    if (
      !['NavigationDuplicated', 'NavigationRedirectedError'].includes(name) &&
      ![2, 4, 8, 16].includes(type)
    ) {
      console.error(error);
    }
  }
  return result;
};

export default VueRouter;

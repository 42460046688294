




































































import { Component, Prop } from 'vue-property-decorator';
import 'vue-advanced-cropper/dist/style.css';
import BaseFormControl from '../../../helpers/BaseFormControl';

@Component({
  name: 'GenericIcon'
})
export default class GenericIcon extends BaseFormControl {
  @Prop({ default: '0 0 42 46' }) readonly viewBox!: string;

  @Prop({ default: '42' }) readonly width!: string;

  @Prop({ default: '46' }) readonly height!: string;

  @Prop({ default: 'XML' }) readonly extensionFile!: string;
}

enum ImproveTicketStatus {
  SCRATCH = 'SCRATCH',
  DRAFT = 'DRAFT',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  WAITING_FOR_IMPLEMENTATION = 'WAITING_FOR_IMPLEMENTATION',
  IMPLEMENTED = 'IMPLEMENTED',
  WONT_DO = 'WONT_DO',
  ARCHIVED = 'ARCHIVED'
}

export default ImproveTicketStatus;

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */
import type { Module } from 'vuex';
import type {
  IOrganization,
  IProductCustomization,
  IWorkflowV2
} from '@rready/sdk';
import type { IWorkflowCompat } from '../../model/workflow/IWorkflowCompat';
import type { IProductConfigCompat } from '../../model/workflow/IProductConfigCompat';
import { WorkflowV2 } from '../../model/workflow/v2/WorkflowV2';
import { ProductConfigV2 } from '../../model/workflow/v2/ProductConfigV2';
import Organization from '../../model/Organization';

interface IContextState {
  organization: IOrganization | undefined;
  currentProductId: string | undefined;
  currentWorkflowId: string | undefined;
  workflows: Map<string, IWorkflowV2>;
}

const module: Module<IContextState, any> = {
  state: {
    currentProductId: undefined,
    currentWorkflowId: undefined,
    organization: undefined,
    workflows: new Map()
  },
  mutations: {
    setupOrganization(state, payload: IOrganization) {
      console.log(`[CONTEXT] Setup Organization: ${payload.name}`);
      state.organization = payload;
    },
    setupWorkflows(state, payload: IWorkflowV2[]) {
      console.log(
        `[CONTEXT] Setup Workflows: ${payload.map((x) => x.name).join(',')}`
      );
      state.workflows = new Map(payload.map((x) => [x.name, x]));
    },
    setupCurrentProduct(state, payload: string) {
      console.log(`[CONTEXT] Setup current product: ${payload}`);
      if (!state.organization?.enabledProducts.includes(payload) ?? false) {
        console.warn(`Product '${payload}' is not enabled or does not exist`);
      }
      state.currentProductId = payload;
      state.currentWorkflowId = payload;
    }
  },
  getters: {
    getContextLoaded(state): boolean {
      return (
        !!state.organization &&
        !!state.currentProductId &&
        !!state.currentWorkflowId
      );
    },
    getOrganization(state): Organization {
      if (!state.organization) {
        throw new Error('No organization is loaded [getOrganization]');
      }
      return Organization.compat(state.organization);
    },
    getCurrentContext(state): string {
      if (!state.organization) {
        throw new Error('No organization is loaded [getCurrentContext]');
      }
      return state.organization.context;
    },
    getCurrentProductId(state): string {
      if (!state.currentProductId) {
        throw new Error('No current product has been set');
      }
      return state.currentProductId;
    },
    getProductCustomization(state): IProductCustomization {
      if (!state.organization) {
        throw new Error('No organization is loaded [getProductCustomization]');
      }
      if (!state.currentProductId) {
        throw new Error('No current product has been set');
      }
      return state.organization.productCustomization?.[state.currentProductId];
    },
    getProductConfig(state): IProductConfigCompat {
      if (!state.organization) {
        throw new Error('No organization is loaded [getProductConfig]');
      }
      if (!state.currentProductId) {
        throw new Error('No current product has been set');
      }
      if (!state.currentWorkflowId) {
        throw new Error('No current workflow has been set');
      }

      const workflow = state.workflows.get(state.currentWorkflowId);
      if (workflow === undefined) {
        throw new Error(`Workflow ${state.currentWorkflowId} is not loaded`);
      }
      return new ProductConfigV2(workflow);
    },
    getProductWorkflow(state): IWorkflowCompat {
      if (!state.organization) {
        throw new Error('No organization is loaded');
      }
      if (!state.currentProductId) {
        throw new Error('No current product has been set');
      }
      if (!state.currentWorkflowId) {
        throw new Error('No current workflow has been set');
      }

      const workflow = state.workflows.get(state.currentWorkflowId);
      if (!workflow) {
        throw new Error(`Workflow ${state.currentWorkflowId} is not loaded`);
      }
      return new WorkflowV2(workflow);
    }
  }
};

export default module;



















































import { Component, Prop } from 'vue-property-decorator';
import styleVariables from '../../styles/export.module.scss';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseTextArea',
  components: {
    BaseControlWrapper
  }
})
export default class BaseTextArea extends BaseFormControl {
  @Prop({ default: '5' }) readonly rows!: string;

  @Prop({ default: null }) readonly appendText!: string;

  styleVariables = styleVariables;

  created(): void {
    super.created();
  }

  clickSubmitButton($event: KeyboardEvent): void {
    // Find & click the closes submit button on control + enter
    if ($event.code === 'Enter' && ($event.metaKey || $event.ctrlKey)) {
      const submitButton: HTMLElement = ($event.target as HTMLElement)
        ?.closest('form')
        ?.querySelector('button[type=submit]') as HTMLElement;
      submitButton?.click();
    }
  }
}

export default class TicketStatsParams {
  assignee: Array<string> | null;

  creator: Array<string> | null;

  delegate: Array<string> | null;

  cached: boolean;

  type: string;

  constructor(type: string) {
    this.assignee = null;
    this.creator = null;
    this.delegate = null;
    this.cached = true;
    this.type = type;
  }

  buildUrl(): string {
    const params = [];

    // Set stats filter params for assignee
    if (this.assignee && this.assignee.length) {
      params.push(this.assignee.map((_) => `assignee=${_}`).join('&'));
    }

    // Set stats filter params for creator
    if (this.creator && this.creator.length) {
      params.push(this.creator.map((_) => `creator=${_}`).join('&'));
    }

    // Set stats filter params for delegate
    if (this.delegate && this.delegate.length) {
      params.push(this.delegate.map((_) => `delegate=${_}`).join('&'));
    }

    // Set param for cached
    params.push(`cached=${this.cached}`);

    // Set search params for type
    params.push(`type=${this.type}`);

    return params.join('&');
  }
}

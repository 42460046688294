










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseHelp from './BaseHelp.vue';

@Component({
  name: 'HelpIcon',
  components: { BaseHelp }
})
export default class HelpIcon extends Vue {
  @Prop({ default: '' }) title!: string;

  @Prop({ default: '' }) text!: string;
}

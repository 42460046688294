





































import { Component } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import BaseFormControl from '../../helpers/BaseFormControl';
import HelpIcon from '../widgets/HelpIcon.vue';

@Component({
  name: 'BaseControlWrapper',
  components: {
    ValidationProvider,
    HelpIcon
  }
})
export default class BaseControlWrapper extends BaseFormControl {
  created(): void {
    super.created();
  }

  onFocus(flag: boolean): void {
    this.focused = flag;
  }
}

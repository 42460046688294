







































import Vue from 'vue';
import { instanceToInstance } from 'class-transformer';
import type { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Task from '@rready/common-utils/src/model/Task';
import { ProductConfigV2 } from '@rready/common-utils/src/model/workflow/v2/ProductConfigV2';
import type TaskUpdateParams from '@rready/common-utils/src/types/TaskUpdateParams';
import type Ticket from '@rready/common-utils/src/model/Ticket';
import BaseTask from './BaseTask.vue';

@Component({
  name: 'BaseTasks',
  components: {
    BaseTask
  }
})
export default class BaseTasks extends Vue {
  @Prop({ default: false }) readonly showLabel!: boolean;

  @Prop({ default: false }) readonly addTasks!: boolean;

  @Prop({ default: false }) readonly reactive!: boolean;

  @Prop({ default: false }) readonly editTaskStatus!: boolean;

  @Prop({ default: false }) readonly editTaskTitle!: boolean;

  @Prop({ default: false }) readonly editTaskDeadline!: boolean;

  @Prop({ default: false }) readonly editTaskAssignee!: boolean;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Getter getCurrentContext!: string;

  @Action updateTaskCompletion!: ActionMethod;

  newTask: Task | null = null;

  allTasks: Array<Task> = [];

  tasksAdded: Map<string, Task> = new Map<string, Task>();

  tasksUpdated: Map<string, Task> = new Map<string, Task>();

  tasksRemoved: Map<string, Task> = new Map<string, Task>();

  newTaskPrefix = 'temp-task';

  get bindAttrs(): any {
    const {
      editTaskStatus,
      editTaskTitle,
      editTaskDeadline,
      editTaskAssignee
    } = this;
    return {
      editTaskStatus,
      editTaskTitle,
      editTaskDeadline,
      editTaskAssignee
    };
  }

  get ticketType() {
    return this.ticket.type.toLowerCase();
  }

  async created(): Promise<void> {
    this.init();
  }

  @Watch('ticket.tasks')
  async init(): Promise<void> {
    this.allTasks = (this.ticket?.tasks as Array<Task>) || [];
    this.newTask = await this.createNewTask();
  }

  async createNewTask(): Promise<Task> {
    const workflowData = await this.$datasource.workflow.find(
      this.ticketType,
      this.getCurrentContext
    );
    const productConfig = new ProductConfigV2(workflowData!);

    return new Task(
      productConfig?.taskType ?? '',
      `${this.newTaskPrefix}-${new Date().getTime()}`
    );
  }

  async taskAdded(payload: { task: Task }): Promise<void> {
    if (this.isNewTask(payload.task)) {
      const tsk = instanceToInstance(payload.task);
      this.allTasks.push(tsk);
      this.tasksAdded.set(tsk.id!, payload.task);
      this.notifyReactiveUpdate();
    }
    this.newTask = await this.createNewTask();
    this.$forceUpdate();
    this.focusOnNewTask();
    this.$emit('input', this.allTasks);
  }

  taskUpdated(payload: { task: Task }): void {
    this.allTasks = this.allTasks.map((t) =>
      t.id === payload.task.id ? payload.task : t
    );
    if (!this.isNewTask(payload.task)) {
      this.tasksUpdated.set(payload.task.id!, payload.task);
      this.notifyReactiveUpdate();
    }
    this.$emit('input', this.allTasks);
  }

  async addCommentToTask(payload: {
    newCommentText: string;
    taskId: string;
    currentUserId: string;
  }): Promise<void> {
    await this.$api.comment.createComment(payload.taskId, 'TICKET', {
      author: payload.currentUserId,
      content: payload.newCommentText
    });
  }

  taskDeleted(payload: { task: Task }): void {
    this.allTasks = this.allTasks.filter((t) => t.id !== payload.task.id);
    if (!this.isNewTask(payload.task)) {
      this.tasksRemoved.set(payload.task.id!, payload.task);
      this.notifyReactiveUpdate();
    }
  }

  notifyReactiveUpdate(): void {
    if (this.reactive) {
      this.$emit('reactiveUpdate', this.prepareTasks());
    }
  }

  focusOnNewTask(): void {
    setTimeout(() => {
      const nextEl: any = this.$refs.baseTaskHelper;
      if (nextEl) {
        nextEl.focusOnEditor();
      }
    }, 100);
  }

  isNewTask(task: Task): boolean {
    return task.id?.startsWith(this.newTaskPrefix) || false;
  }

  prepareTasks(): TaskUpdateParams {
    const params = {
      added: [...this.setParent(this.ticket!, this.tasksAdded.values())],
      updated: [...this.setParent(this.ticket!, this.tasksUpdated.values())],
      removed: [...this.setParent(this.ticket!, this.tasksRemoved.values())]
    };
    this.tasksAdded = new Map<string, Task>();
    this.tasksUpdated = new Map<string, Task>();
    this.tasksRemoved = new Map<string, Task>();
    return params;
  }

  setParent(parent: Ticket, tasks: IterableIterator<Task>): Array<Task> {
    return Array.from(tasks).map((t) => {
      const tTemp = t;
      tTemp.parent = parent.canonicalId;
      return tTemp;
    });
  }

  async taskCompleted(payload: {
    task: Task;
    complete: boolean;
  }): Promise<void> {
    await this.updateTaskCompletion({
      parent: payload.task,
      complete: payload.complete
    });
    this.taskUpdated(payload);
  }
}

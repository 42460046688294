




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';

@Component({
  name: 'BaseImageDisplay'
})
export default class BaseImageDisplay extends Vue {
  @Prop({ default: () => [] }) readonly value!: FileModel[];

  @Prop({ default: 'ticket-cover' }) readonly category!: string;

  get image(): string {
    return Array.isArray(this.value)
      ? this.value
          .filter((file) => file.category === this.category)
          .slice(-1)[0]?.url
      : '';
  }
}

import { plainToClass } from 'class-transformer';
import UserAuthObject from '../model/UserAuthObject';
import RoleType from '../types/RoleType';
import { CookieUtils, SECURITY_COOKIE_NAME } from '../utils/cookies';
import { StorageUtils } from '../utils/storage';

const logoutUrl = process.env.VUE_APP_LOGOUT_URL || '/logout';
const authServer = process.env.VUE_APP_AUTHENTICATION_URL;

export const setCurrentAuth = (user: UserAuthObject): void => {
  const userData = JSON.stringify(user);
  StorageUtils.setUser(userData);
};

export const clearCookies = (): void => {
  CookieUtils.remove(SECURITY_COOKIE_NAME);
};

export const clearLocalStorage = (): void => {
  StorageUtils.clear();
};

export const deleteCurrentAuth = (ssoLogoutUrl = ''): void => {
  clearLocalStorage();
  clearCookies();
  const currentUrl = window.location.href;

  // Check if we need to log out using a url
  if (ssoLogoutUrl) {
    window.location.href = ssoLogoutUrl;
    // Check if the logout happens, because of admin login by normal user
  } else if (currentUrl.includes('redirect=')) {
    // Redirect back to login screen with redirect params in tacked
    window.location.href = `${authServer}?${currentUrl.split('?')[1]}`;
  } else if (!currentUrl.includes(logoutUrl)) {
    window.location.href = logoutUrl;
  }
};

export const getCurrentAuth = (): UserAuthObject | null => {
  const user = StorageUtils.getUser();
  if (user) {
    try {
      return plainToClass(UserAuthObject, JSON.parse(user) as UserAuthObject);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const isAuthenticated = (): boolean => !!StorageUtils.getUser();

export const isServicePortal = (): boolean =>
  process.env.VUE_APP_APP_NAME === 'services';

export const userIsAdmin = (user: UserAuthObject): boolean | undefined =>
  user?.roles.includes(RoleType.ADMIN);

export const authHeader = (): { Authorization?: string } => {
  const auth = getCurrentAuth();

  if (auth && auth.token) {
    return {
      Authorization: `Bearer ${auth.token}`
    };
  }
  return {};
};

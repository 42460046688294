

































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'BaseUserAvatar'
})
export default class BaseUserAvatar extends Vue {
  @Prop({ default: undefined }) src!: string;

  @Prop({ default: false }) addAvatar!: boolean;

  @Prop({ default: '' }) tooltip!: string;

  @Prop({ default: 128 }) size!: number;

  @Prop({ default: null }) colorClass!: string;

  @Prop({ default: '' }) faIcon!: string;

  get userIcon(): string {
    return this.addAvatar ? 'fa-user-plus' : 'fa-user';
  }

  get svgHeight(): number {
    // To maintain ratio
    return this.size / 1.5;
  }
}

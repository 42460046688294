import { plainToClass } from 'class-transformer';
import Team from '../model/Team';

import { doSecureDeleteRequest, doSecureGetRequest } from './ApiService';

function removeTeam(teamId: string): Promise<string> {
  return doSecureDeleteRequest<string>(`/team/${teamId}/dependencies`).then(
    (response) => response
  );
}

function fetchOrganizationTeams(): Promise<Array<Team>> {
  return doSecureGetRequest<Array<Team>>(`/team/fetchAll`).then((teams) =>
    plainToClass(Team, teams)
  );
}

function fetchSubscribedTeams(userId: string): Promise<Array<Team>> {
  return doSecureGetRequest<Array<Team>>(
    `/team/${userId}/subscribedTeams`
  ).then((teams) => plainToClass(Team, teams));
}

export default {
  removeTeam,
  fetchOrganizationTeams,
  fetchSubscribedTeams
};

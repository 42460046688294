

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FooterLogo from '@rready/common-components/src/components/widgets/FooterLogo.vue';

@Component({
  name: 'Footer',
  components: {
    FooterLogo
  }
})
export default class Footer extends Vue {
  versionNumber = '';

  async created(): Promise<void> {
    const { version } = await this.$api.users.fetchVersionNumber();
    this.versionNumber = version;
  }
}








import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import FooterLogoImg from '../../assets/logo-footer.svg';

@Component({
  name: 'FooterLogo',
  components: {
    InlineSvg
  }
})
export default class FooterLogo extends Vue {
  footerLogo = FooterLogoImg;
}

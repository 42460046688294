






















import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component({
  name: 'BaseButton',
  inheritAttrs: false
})
export default class BaseButton extends Vue {
  @Inject() readonly trackClickEvent!: (title: string) => void;

  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly customId!: string;

  get isDepressed(): boolean {
    return Object.prototype.hasOwnProperty.call(this.$attrs, 'depressed');
  }

  get elevation(): string {
    return this.isDepressed ? '' : '0';
  }

  get buttonId(): string {
    return (
      this.customId ||
      `base-button-${this.title?.replace(/\s+/g, '-').toLowerCase()}`
    );
  }
}








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BasePill'
})
export default class BasePill extends Vue {
  @Prop({ default: '' }) readonly label!: string;

  @Prop({ default: false }) readonly medium!: boolean;

  @Prop({ default: false }) readonly large!: boolean;
}




















































import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import type { ActionMethod } from 'vuex';
import { Component, Prop } from 'vue-property-decorator';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';

@Component({
  name: 'NavigationDrawer'
})
export default class NavigationDrawer extends Vue {
  @Prop({ default: () => [] }) options!: MenuOption[];

  @Getter drawerIsOpen?: boolean;

  @Action toggleNavigationalDrawer!: ActionMethod;

  isSelected(value: string): string {
    const { name, meta } = this.$route;
    return value && [name, meta?.selectedNavigationItem].includes(value)
      ? 'selected'
      : '';
  }

  goToRoute(name: string, refresh = false): void {
    if (!name) {
      return;
    }
    this.toggleNavigationalDrawer(false);
    this.$router.push({
      name,
      // Force a fresh of the route
      ...(refresh &&
        this.$route.name === name && {
          query: { refresh: `${this.$route.query.refresh || 0}1` }
        })
    });
  }
}

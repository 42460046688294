var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-control-container"},[_c('base-control-wrapper',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){
var onFocus = ref.onFocus;
return [_c('v-textarea',{ref:_vm.elementId,staticClass:"base-control-container-text-area",attrs:{"id":_vm.elementId,"background-color":_vm.styleVariables.lightGrey,"data-ruid":("input-" + _vm.id),"disabled":_vm.disabled,"placeholder":_vm.placeholderText,"readonly":_vm.disabled,"value":_vm.$attrs.value,"rows":_vm.rows,"dense":"","flat":"","hide-details":"","solo":"","auto-grow":""},on:{"blur":function () { return onFocus(false); },"focus":function () { return onFocus(true); },"input":function($event){return _vm.$emit('input', $event)},"keydown":function($event){_vm.clickSubmitButton;
          _vm.$emit('onKeyDown', $event);}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.appendText)?_c('div',{staticClass:"append-text"},[_vm._v(" "+_vm._s(_vm.appendText)+" ")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" >")])]}},{key:"errors",fn:function(ref){
          var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import type User from '@rready/common-utils/src/model/User';
import Topic from '@rready/common-utils/src/model/Topic';
import type { DropDownOption } from '@rready/common-utils/src/types/DropDownOption';
import BaseAutoComplete from '../core/BaseAutoComplete.vue';
import styleVariables from '../../styles/export.module.scss';

@Component({
  name: 'TopicSelector',
  components: {
    BaseAutoComplete
  }
})
export default class TopicSelector extends Vue {
  @Getter currentUser?: User;

  @Prop({ default: () => [] }) readonly topics!: Topic[];

  options: DropDownOption[] = [];

  styleVariables = styleVariables;

  async mounted(): Promise<void> {
    const userTeamId = this.currentUser?.unit ?? '';

    const topicsByTeam = await this.$api.topic.getTopicByTeam(
      userTeamId,
      'relates'
    );
    const topicsRelated = (
      await this.$datasource.topic.listBatch(topicsByTeam.topicIds)
    ).map((x) => Topic.compat(x));

    const topicsOther = this.topics.filter(
      (x) => !topicsByTeam.topicIds.includes(x.id!)
    );

    const opt: DropDownOption[] = [];
    if (topicsRelated.length > 0) {
      opt.push({
        header: this.$t(`page.topicOverview.relationTypes.relates`).toString()
      });
      opt.push(...topicsRelated.map(this.mapTopicToDropDownOption));
    }

    if (topicsOther.length > 0) {
      opt.push({
        header: this.$t(`page.topicOverview.relationTypes.other`).toString()
      });
      opt.push(...topicsOther.map(this.mapTopicToDropDownOption));
    }
    this.options = opt;
  }

  mapTopicToDropDownOption(topic: Topic): DropDownOption {
    return {
      id: topic.id,
      name: topic.metaData.displayName,
      color: topic.metaData.color
    } as DropDownOption;
  }
}

export default class UserMetaInfo {
  constructor() {
    this.about = null;
    this.jobTitle = null;
    this.phoneNumber = null;
    this.linkedInUrl = null;
    this.twitter = null;
    this.tagline = null;
    this.experience = null;
  }

  about: string | null;

  jobTitle: string | null;

  tagline: string | null;

  experience: string | null;

  phoneNumber: string | null;

  linkedInUrl: string | null;

  twitter: string | null;
}

import * as components from './components';

const rreadyVuetifyUi = {
  install(app: any) {
    Object.entries(components).forEach(([name, component]) => {
      app.component(name, component);
    });
  }
};

export { rreadyVuetifyUi };
export * from './components';

import * as Throttle from 'promise-parallel-throttle';
import type { SingleFileResponse } from '../types/FileUploadResponse';
import type {
  GetSignedUrlRequest,
  StartMultiUploadRequest,
  StopMultiUploadRequest
} from '../types/MultiUploadRequest';
import type {
  GetSignedUrlResponse,
  StartMultiUploadResponse,
  StopMultiUploadResponse
} from '../types/MultiUploadResponse';
import {
  doSecureDeleteRequest,
  doSecureFileUpload,
  doSecurePostRequest
} from './ApiService';
import type { VUFile } from '../types/VUFile';
import type { UploadState } from '../types/UploadState';

// Max part number is 10,000
// https://docs.aws.amazon.com/AmazonS3/latest/API/API_UploadPart.html#API_UploadPart_RequestSyntax
const bufferSize = 5 * 1024 * 1024;
// The minimum size for segments is 5 Mb
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html

// Unfortunately I can't access the store from here so I'll store it here for now
const cancelledUploadIds: string[] = [];

function cancelUpload(id: string): void {
  cancelledUploadIds.push(id);
}

function isCancelled(id: string): boolean {
  return cancelledUploadIds.includes(id);
}

function dataUrlFileSize(dataUrl: string): number {
  const base64str = dataUrl.split('base64,')[1];
  const decoded = atob(base64str);

  return decoded.length;
}

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  const [, type] = dataUrl.split(';')[0].split(':');
  return new File([blob], fileName, { type });
}

function convertVideo(canonicalId: string, fieldName: string): void {
  doSecurePostRequest<void>(`/video/${canonicalId}/${fieldName}/convert`);
}

function startMultiUpload(
  ticketId: string,
  payload: StartMultiUploadRequest
): Promise<StartMultiUploadResponse> {
  return doSecurePostRequest<StartMultiUploadResponse>(
    `/ticket/fileUpload/${ticketId}/start`,
    payload
  );
}

function getSignedUrl(
  payload: GetSignedUrlRequest
): Promise<GetSignedUrlResponse> {
  return doSecurePostRequest<GetSignedUrlResponse>(
    '/fileUpload/multiUpload/getSignedUrl',
    payload
  );
}

function cancelMultiUpload(
  payload: Partial<StopMultiUploadRequest>
): Promise<void> {
  cancelUpload(payload.uploadId!);
  return doSecurePostRequest<void>(`/ticket/fileUpload/cancel`, payload);
}

function stopMultiUpload(
  ticketId: string,
  payload: StopMultiUploadRequest
): Promise<StopMultiUploadResponse> {
  return doSecurePostRequest<StopMultiUploadResponse>(
    `/ticket/fileUpload/${ticketId}/stop`,
    payload
  );
}

async function processMultiUpload(
  inputId: string,
  uploadId: string,
  file: VUFile,
  key: string,
  callBack: (up: UploadState) => void
): Promise<string[]> {
  const segments: number = Math.ceil(file.size / bufferSize);
  const tasks: boolean[] = Array(segments).fill(false);
  const contentType: string = file.type;

  const parts: string[] = await Throttle.all(
    tasks.map(Function.call, (i: number) => async (): Promise<string> => {
      if (isCancelled(uploadId)) {
        throw new Error('Upload aborted');
      }
      const url = await getSignedUrl({
        key,
        uploadId,
        contentType,
        partNumber: i + 1
      });
      const start = i * bufferSize;
      const end = start + bufferSize;
      const response: Response = await fetch(url, {
        method: 'PUT',
        body: file.file.slice(start, end > file.size ? file.size : end)
      });
      tasks[i] = true;
      return response.headers.get('etag') || '';
    }) as Throttle.Task<string>[],
    {
      maxInProgress: 2,
      progressCallback(progress) {
        if (!uploadId) {
          return;
        }
        callBack({
          inputId,
          uploadProgress: (100 * progress.amountDone) / segments
        });
      }
    }
  );
  return parts;
}

function removeAttachment(
  ticketCanonicalId: string,
  fileId: string
): Promise<void> {
  return doSecureDeleteRequest<void>(
    `/ticket/fileUpload/${ticketCanonicalId}/${fileId}`
  );
}

function uploadFile(
  fileName: string,
  prefix: string,
  payload: File
): Promise<SingleFileResponse> {
  const formData = new FormData();
  formData.append('file', payload);
  formData.append('prefix', prefix);
  formData.append('tags', '');
  return doSecureFileUpload<SingleFileResponse>('/fileUpload', formData);
}

export {
  convertVideo,
  dataUrlToFile,
  dataUrlFileSize,
  uploadFile,
  processMultiUpload,
  startMultiUpload,
  cancelMultiUpload,
  stopMultiUpload,
  removeAttachment,
  getSignedUrl
};

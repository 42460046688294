
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import type User from '@rready/common-utils/src/model/User';
import GenericFileIcon from '../widgets/media/GenericFileIcon.vue';
import videoIcon from '../../assets/video.svg';

@Component({
  name: 'BaseFilesDisplay',
  components: {
    GenericFileIcon
  }
})
export default class BaseFilesDisplay extends Vue {
  @Getter currentUser?: User;

  @Prop({ default: () => [] }) readonly value!: FileModel[];

  icons = { video: videoIcon };

  isImage(mimeType: string): boolean {
    return !!mimeType?.includes('image');
  }

  isVideo(mimeType: string): boolean {
    return !!mimeType?.includes('video');
  }

  getFileExtension(file: FileModel): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(file.filename)![1]?.toUpperCase() || '';
  }

  textFileName(file: FileModel): string {
    return file.filename.length < 16
      ? file.filename
      : file.filename.substr(0, 13).concat('...');
  }

  downloadFile(file: FileModel): void {
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${file.filename.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}

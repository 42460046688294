import type { IWorkflowV2 } from '@rready/sdk';
import { doSecureGetRequest } from './ApiService';

function fetchWorkflow(workflowName: string): Promise<IWorkflowV2> {
  return doSecureGetRequest<IWorkflowV2>(`/workflow/${workflowName}`).then(
    (w) => w
  );
}

export default {
  fetchWorkflow
};

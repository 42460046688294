
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import styleVariables from '../../styles/export.module.scss';
import BaseMenu from './BaseMenu.vue';
import BaseUserAvatar from './BaseUserAvatar.vue';
import BasePill from './BasePill.vue';

@Component({
  name: 'BaseTeamMemberCard',
  components: {
    BaseMenu,
    BaseUserAvatar,
    BasePill
  }
})
export default class BaseTeamMemberCard extends Vue {
  clicked = false;

  @Prop({ default: null }) avatar!: string;

  @Prop({ default: '' }) defaultIcon!: string;

  @Prop({ default: 46 }) avatarSize!: number;

  @Prop({ default: null }) title!: string;

  @Prop({ default: null }) subtitle!: string;

  @Prop({ default: 300 }) cardWidth!: number;

  @Prop({ default: 85 }) cardHeight!: number;

  @Prop({ default: false }) showRemoveButton!: boolean;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  @Prop({ default: false }) isHighlighted!: boolean;

  @Prop({ default: null }) role!: string;

  styleVariables = styleVariables;

  get maxTextSize(): string {
    let totalWhiteSpace: number;
    if (this.menuOptions.length) {
      totalWhiteSpace = 75;
    } else if (this.showRemoveButton) {
      totalWhiteSpace = 65;
    } else {
      totalWhiteSpace = 50;
    }
    return `max-width: ${
      this.cardWidth - this.avatarSize - totalWhiteSpace
    }px;`;
  }
}

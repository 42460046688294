import { plainToClass } from 'class-transformer';
import type { DateTimeNumber } from '@rready/sdk';
import type Reaction from './Reaction';

export interface ICommentCompat {
  author: string;
  content: string;
  createdAt: DateTimeNumber;
  id: string;
  reactions: unknown[];
  thread: unknown[];
  updatedAt: DateTimeNumber;
}

export default class Comment {
  public static compat(value: ICommentCompat): Comment {
    return plainToClass(Comment, value);
  }

  constructor(
    id: string | null,
    context: string,
    author: string,
    createdAt: Date,
    updatedAt: Date,
    thread: Array<Comment>,
    reactions: Array<Reaction>
  ) {
    this.id = id;
    this.content = context;
    this.author = author;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.thread = thread;
    this.reactions = reactions;
  }

  id: string | null;

  content: string;

  author: string;

  createdAt: Date;

  updatedAt: Date;

  thread: Array<Comment>;

  reactions: Array<Reaction>;
}

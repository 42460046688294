


























































import { Component, Prop } from 'vue-property-decorator';
import styleVariables from '../../styles/export.module.scss';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseTextInput',
  components: {
    BaseControlWrapper
  }
})
export default class BaseTextInput extends BaseFormControl {
  @Prop({ default: 'off' }) autocomplete!: string;

  styleVariables = styleVariables;

  created(): void {
    super.created();
  }

  isNumber(event: KeyboardEvent): void {
    if (this.type === 'number') {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    }
  }
}

// tslint:disable-file
import {
  authHeader,
  deleteCurrentAuth,
  getCurrentAuth
} from './SecurityService';
import { StorageUtils } from '../utils/storage';

const defaultEventHeaders = {
  'app-id': process.env.VUE_APP_APP_NAME ?? '',
  product: StorageUtils.getProductId() ?? ''
};

const config: { apiUrl: string } = {
  apiUrl: process.env.VUE_APP_API_URL!
};

const stringify = (payload: unknown) => JSON.stringify(payload);

function getApiUrl(useContext?: boolean): string {
  const context = getCurrentAuth()?.context;
  if (context && useContext) {
    return `${config.apiUrl}/${context}`;
  }
  return config.apiUrl;
}

function encodeUrl(url: string): string {
  const replacedHashUrl: string = url.replaceAll('#', '--__--');
  const { origin, searchParams, pathname } = new URL(
    `${getApiUrl(true)}${replacedHashUrl}`
  );
  const queryParams = [...searchParams.entries()]
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return (
    `${origin}${pathname}?${queryParams}`
      // Replace the temp character with the encoded hashtag
      .replaceAll('--__--', '%23')
  );
}

async function handleResponse(response: Response) {
  const text: string = await response.text();
  // If response.status is 406 it means unacceptable jwt was provided
  if (response.status === 406) {
    return deleteCurrentAuth();
  }
  // If response.status is 500 it means something was wrong with the server
  if (response.status === 500) {
    return Promise.reject(new Error('error.systemFailure'));
  }
  if (!response.ok) {
    return Promise.reject(text);
  }

  try {
    const data = JSON.parse(text);
    return Promise.resolve(data);
  } catch (ex) {
    return Promise.resolve(text);
  }
}

export const doGetRequest = async <T>(
  url: string,
  headers?: Record<string, string>,
  relativeUrl = true,
  encodeSearchParams = true,
  useContext = false
): Promise<T> => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...defaultEventHeaders,
      ...headers
    }
  };
  let realUrl: string | undefined;
  if (relativeUrl) {
    realUrl = encodeSearchParams
      ? encodeUrl(url)
      : `${getApiUrl(useContext)}${url}`;
  } else {
    realUrl = url;
  }
  const response = await fetch(realUrl, requestOptions);
  return handleResponse(response);
};

export const doSecureGetRequest = async <T>(
  url: string,
  headers?: Record<string, string>,
  relativeUrl = true,
  encodeSearchParams = true
): Promise<T> =>
  doGetRequest(
    url,
    {
      ...authHeader(),
      ...headers
    },
    relativeUrl,
    encodeSearchParams,
    true
  );

export const doPostRequest = async <T>(
  url: string,
  payload?: unknown,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...defaultEventHeaders,
      ...headers
    },
    body: stringify(payload)
  };

  const useContextPrefix = url !== '/set-password';
  const response = await fetch(
    `${getApiUrl(useContextPrefix)}${url}`,
    requestOptions
  );
  return handleResponse(response);
};

export const doSecureFileUpload = async <T>(
  url: string,
  payload?: unknown
): Promise<T> => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...defaultEventHeaders,
      ...authHeader()
    },
    body: payload
  };

  const response = await fetch(
    `${getApiUrl(true)}${url}`,
    requestOptions as any
  );
  return handleResponse(response);
};

export const doSecurePostRequest = <T>(
  url: string,
  payload?: unknown,
  headers?: Record<string, string>
): Promise<T> =>
  doPostRequest<T>(url, payload, {
    ...authHeader(),
    ...headers
  });

export const doSecurePutRequest = async <T>(
  url: string,
  payload?: unknown,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...defaultEventHeaders,
      ...authHeader(),
      ...headers
    },
    body: stringify(payload)
  };
  const response = await fetch(`${getApiUrl(true)}${url}`, requestOptions);
  return handleResponse(response);
};

export const doSecureDeleteRequest = async <T>(
  url: string,
  payload?: unknown,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...defaultEventHeaders,
      ...authHeader(),
      ...headers
    },
    body: stringify(payload)
  };
  const response = await fetch(`${getApiUrl(true)}${url}`, requestOptions);
  return handleResponse(response);
};

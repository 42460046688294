import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { plainToClass } from 'class-transformer';
import type { TeamState } from '../../types/TeamState';
import Team, { TEAM_TYPE } from '../../model/Team';
import TeamService from '../../services/TeamService';

const state: TeamState = {
  teams: [],
  teamsByID: new Map<string, Team>(),
  organizationalUnits: [],
  organizationalUnitsByID: new Map<string, Team>()
};

const updateTeamsByID = () => {
  state.teamsByID = new Map<string, Team>(state.teams.map((t) => [t.id!, t]));
};

const updateOrgByID = () => {
  state.organizationalUnitsByID = new Map<string, Team>(
    state.organizationalUnits.map((t) => [t.id!, t])
  );
};

const getters: GetterTree<TeamState, any> = {
  teams: (st) => st.teams,
  teamsByID: (st) => st.teamsByID,

  organizationalUnits: (st) => st.organizationalUnits,
  organizationalUnitsByID: (st) => st.organizationalUnitsByID,

  allTeamsById: (st) => (id: string) =>
    st.teamsByID.get(id) || st.organizationalUnitsByID.get(id)
};

const mutations: MutationTree<TeamState> = {
  setUnitsAndTeams(st: TeamState, payload: Team[]) {
    const units: Array<Team> = [];
    const teams: Array<Team> = [];
    const unitsById = new Map<string, Team>();
    const teamsById = new Map<string, Team>();

    payload.forEach((t) => {
      const teamInstance = plainToClass(Team, t);

      if (teamInstance.type === TEAM_TYPE.ORGANIZATIONAL_TEAM) {
        units.push(teamInstance);
        unitsById.set(teamInstance.id!, teamInstance);
      }
      if (teamInstance.type === TEAM_TYPE.VIRTUAL_TEAM) {
        teams.push(teamInstance);
        teamsById.set(teamInstance.id!, teamInstance);
      }
    });
    st.organizationalUnits = units;
    updateOrgByID();
    st.teams = teams;
    updateTeamsByID();
  },
  removeVirtualTeam(st: TeamState, teamId: string) {
    st.teams = st.teams.filter((_: Team) => _.id !== teamId);
    updateTeamsByID();
  },
  removeOrganizationalTeam(st: TeamState, teamId: string) {
    st.organizationalUnits = st.organizationalUnits.filter(
      (_: Team) => _.id !== teamId
    );
    updateOrgByID();
  }
};

const actions: ActionTree<TeamState, any> = {
  async fetchOrganizationTeams({ commit }): Promise<Array<Team>> {
    const teams = await TeamService.fetchOrganizationTeams();

    commit('setUnitsAndTeams', teams);

    return Promise.resolve(teams);
  },

  async removeTeam({ commit }, team: Team): Promise<string> {
    await TeamService.removeTeam(team.id!);
    const mutation =
      team.type === TEAM_TYPE.ORGANIZATIONAL_TEAM
        ? 'removeOrganizationalTeam'
        : 'removeVirtualTeam';
    commit(mutation, team.id);
    return team.id!;
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};

import * as Sentry from '@sentry/vue';
import smartlookClient from 'smartlook-client';
import googleAnalytics from './googleAnalytics';
import type User from '../model/User';
import type Organization from '../model/Organization';

export default {
  initSmartlook(user: User): void {
    if (
      process.env.NODE_ENV === 'production' &&
      !process.env.VUE_APP_SMARTLOOK_RESTRICTED_CONTEXTS!.includes(
        user.context!
      )
    ) {
      smartlookClient.init(process.env.VUE_APP_SMARTLOOK_TOKEN!);
      smartlookClient.identify(user.id!, {
        context: user.context!
      });
    }
  },

  setUserData(user: User): void {
    googleAnalytics.setUserProperties({
      user_language: user?.language || 'UNSET',
      unit: user?.unit || 'UNSET',
      company: user?.context || 'UNSET'
    });
    googleAnalytics.setUserId(user.id!);

    Sentry.configureScope((scope): void => {
      scope.setUser({
        id: user.id,
        context: user.context,
        language: user.language
      });
      scope.setTag('page_locale', user.language);
    });
  },

  // Custom events
  trackCustomEvent(name: string, parameters = {}): void {
    googleAnalytics.sendEvent(name, parameters);
  },

  // Click events
  trackClickEvent(
    label: string,
    pageTitle: string,
    currentUser: User,
    currentOrg: Organization
  ): void {
    googleAnalytics.sendEvent('click', {
      timestamp: Date.now().toString(),
      company_id: currentOrg?.id || 'UNSET',
      user_id: currentUser?.id || 'UNSET',
      user_unit: currentUser?.unit || 'UNSET',
      user_language: currentUser?.language || 'UNSET',
      page_title: pageTitle,
      button_label: label.replaceAll(' ', '_')
    });
  }
};

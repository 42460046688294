import NodeType from '../types/NodeType';
import type { IDelegatable } from './Delegate';
import Delegate from './Delegate';
import TopicMetaData from './TopicMetaData';

export enum TopicRelationTypes {
  OTHER = 'other',
  RELATES = 'relates',
  PREFERRED = 'preferred'
}

export enum TopicMemberType {
  TAG_OWNER = 'TAG_OWNER',
  TAG_OWNER_LEVEL = 3201,
  TAG_MEMBER = 'TAG_MEMBER',
  TAG_MEMBER_LEVEL = 3203,
  TAG_EXPERT = 'TAG_EXPERT',
  TAG_EXPERT_LEVEL = 3203
}

// IP-2228 Review
export interface ITopicCompat {
  context: string;
  id: string;
  meta: any;
}

export default class Topic implements IDelegatable {
  public static compat(value: ITopicCompat): Topic {
    const topic = new Topic(value.id);
    topic.metaData = value.meta;
    return topic;
  }

  id: string | null;

  canonicalId: string | undefined;

  count: number | undefined;

  tagName: string;

  tagType: string;

  metaData: TopicMetaData;

  relatesTo: TopicRelationTypes[];

  constructor(
    id: string | null = null,
    tagName = '',
    tagType: string | null = null,
    relatesTo: TopicRelationTypes[] = []
  ) {
    this.id = id;
    this.tagName = tagName;
    this.tagType = tagType || NodeType.TOPIC;
    this.metaData = new TopicMetaData();
    this.relatesTo = relatesTo || [];
  }

  asDelegate(): Delegate {
    if (!this.id) {
      throw new Error('Could not convert Topic into delegate');
    }
    return new Delegate(this.id, 'topic');
  }

  getDelegateId(): string {
    console.warn(
      `Topic 'getDelegateId' is obsolete [${this.tagType}:${this.id}]`
    );
    return `${this.tagType}:${this.id}`;
  }
}













































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CircleStencil, Cropper, RectangleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import type {
  Coordinates,
  CropperType,
  ImageCropperParams
} from '../../../types/Cropper';
import uploadPhotoIcon from '../../../assets/upload-photo-icon.svg';
import cameraIcon from '../../../assets/camera.svg';
import PrimaryButton from '../PrimaryButton.vue';
import SecondaryButton from '../SecondaryButton.vue';

@Component({
  name: 'ImageCropper',
  components: {
    PrimaryButton,
    SecondaryButton,
    Cropper,
    CircleStencil,
    RectangleStencil
  }
})
export default class ImageCropper extends Vue {
  @Prop({ default: null }) readonly value!: string;

  @Prop({ default: 16 / 9 }) readonly aspectRatio!: number;

  @Prop({ default: false }) readonly isRound!: boolean;

  @Prop({ default: false }) readonly disabled!: boolean;

  cropImageModal = false;

  croppedImage = '';

  coordinates: Coordinates | null = null;

  oldCoordinates: Coordinates | null = null;

  file: File | undefined;

  oldValue = '';

  uploadPhotoIcon = uploadPhotoIcon;

  cameraIcon = cameraIcon;

  setImage(): void {
    if (!this.croppedImage) {
      this.croppedImage = this.value;
    }
  }

  created(): void {
    this.setImage();
  }

  beforeUpdate(): void {
    this.setImage();
  }

  selectFile(): void {
    (this.$refs.file! as any).click();
  }

  defaultSize({ imageSize }: ImageCropperParams): Record<string, number> {
    const size =
      imageSize.width > imageSize.height ? imageSize.height : imageSize.width;
    return {
      width: size,
      height: size
    };
  }

  pixelsRestriction({
    imageSize: { width, height }
  }: ImageCropperParams): Record<string, number> {
    if (this.isRound) {
      const minWidthHeight = Math.min(width, height, 200);
      return {
        minWidth: minWidthHeight,
        minHeight: minWidthHeight
      };
    }
    const minWidth = Math.min(700, width, height * this.aspectRatio);
    const minHeight = Math.min(height, minWidth / this.aspectRatio);
    return {
      minWidth,
      minHeight
    };
  }

  inputPhoto(): void {
    const fileUploader = this.$refs.file as HTMLInputElement;
    this.file = fileUploader?.files![0];
    const reader = new FileReader();
    this.storeOriginalImage();
    this.coordinates = null;
    // Set the file = null before changing the file so the cropper doesn't throw an error
    this.$emit('input', null);
    reader.onload = (e: Event) => {
      this.$emit('input', (e?.target as FileReader).result);
      fileUploader.value = '';
    };
    reader.readAsDataURL(this.file);
    this.cropImageModal = true;
  }

  cropPhoto(): void {
    const { canvas, coordinates } = (
      this.$refs.cropper as unknown as CropperType
    ).getResult();
    if (!canvas) return;
    this.cropImageModal = false;
    this.coordinates = coordinates;
    const resizeCanvas = this.resizeImage(canvas);
    this.croppedImage = resizeCanvas.toDataURL('image/webp');
    this.$emit('change', this.croppedImage, this.file);
  }

  resizeImage(
    canvasImage: HTMLCanvasElement,
    maxWidth = 1000,
    maxHeight = 1000 / this.aspectRatio
  ): HTMLCanvasElement {
    // The max sizes are 2x what is currently used
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let ratio = 1;
    if (canvasImage.width > maxWidth) {
      ratio = maxWidth / canvasImage.width;
    } else if (canvasImage.height > maxHeight) {
      ratio = maxHeight / canvasImage.height;
    }
    canvas.width = canvasImage.width * ratio;
    canvas.height = canvasImage.height * ratio;
    ctx?.drawImage(
      canvasImage,
      0,
      0,
      canvasImage.width,
      canvasImage.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    return canvas;
  }

  cancelPhotoSelect(): void {
    this.cropImageModal = false;
    this.restoreOldImage();
  }

  storeOriginalImage(): void {
    this.oldValue = this.value;
    this.oldCoordinates = { ...this.coordinates };
  }

  restoreOldImage(): void {
    this.coordinates = { ...this.oldCoordinates };
    this.croppedImage = this.oldValue;
    this.$emit('input', this.oldValue);
  }
}

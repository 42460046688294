































































































































































import Vue from 'vue';
import { instanceToInstance } from 'class-transformer';
import { Action, Getter } from 'vuex-class';
import type { ActionMethod } from 'vuex';
import { Component, Prop } from 'vue-property-decorator';
import { distinctValues } from '@rready/sdk';
import type { IUser } from '@rready/sdk';
import type User from '@rready/common-utils/src/model/User';
import type Task from '@rready/common-utils/src/model/Task';
import InlineSvg from 'vue-inline-svg';
import TaskStatus from '@rready/common-utils/src/types/TaskStatus';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import Comment from '@rready/common-utils/src/model/Comment';
import BaseCheckbox from '../core/BaseCheckbox.vue';
import BaseDatePicker from '../core/BaseDatePicker.vue';
import BaseUserAssign from '../user/BaseUserAssign.vue';
import BaseUserAvatar from '../widgets/BaseUserAvatar.vue';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseMenu from '../widgets/BaseMenu.vue';
import calendar from '../../assets/calendar-icon.svg';
import addComment from '../../assets/add-comment.svg';
import checkComments from '../../assets/check-comments.svg';

@Component({
  name: 'BaseTask',
  components: {
    BaseCheckbox,
    BaseUserAvatar,
    BaseMenu,
    BaseUserAssign,
    BaseDatePicker,
    BaseTextArea,
    InlineSvg
  }
})
export default class BaseTask extends Vue {
  @Action setGlobalLoading!: ActionMethod;

  @Getter users!: Array<User>;

  @Getter currentUser?: User;

  @Prop({ default: null }) readonly task!: Task;

  @Prop({ default: false }) readonly editTask!: boolean;

  @Prop({ default: false }) readonly editTaskStatus!: boolean;

  @Prop({ default: false }) readonly editTaskTitle!: boolean;

  @Prop({ default: false }) readonly editTaskDeadline!: boolean;

  @Prop({ default: false }) readonly editTaskAssignee!: boolean;

  @Prop({ default: false }) readonly helper!: boolean;

  menuOptions: MenuOption[] = [
    {
      title: this.$t('menu.markAsDone').toString(),
      value: 'markAsDone'
    },
    {
      title: this.$t('menu.removeTask').toString(),
      value: 'removeTask'
    }
  ];

  title: string | null = '';

  deadLine: Date | null = null;

  assigneeId: string | null = null;

  taskToEdit!: Task;

  calendarIcon = calendar;

  addCommentIcon = addComment;

  checkCommentsIcon = checkComments;

  showUserSelector = false;

  editTitle = false;

  readyToRemove = false;

  taskUpdated = false;

  completed = false;

  showComments = false;

  newCommentText = '';

  commentsTask: Comment[] = [];

  usersComments: Array<IUser> = [];

  taskStatusReloadKey = 0;

  get expired(): boolean {
    return this.deadLine ? new Date(this.deadLine) < new Date() : false;
  }

  get currentUserAvatar(): string {
    return this.currentUser?.avatar || '';
  }

  getCommentAvatar(comment: Comment): string | undefined {
    const user = this.usersComments.find((x) => x.id === comment.author);
    return user?.avatar;
  }

  getTimePassedFromComment(comment: Comment): string {
    return this.$date(comment.createdAt).fromNow();
  }

  get assigneeAvatar(): string {
    return this.assignee?.avatar || '';
  }

  get assigneeName(): string {
    return this.assignee
      ? `${this.assignee.firstName} ${this.assignee.lastName}`
      : '';
  }

  get assignee(): User | null {
    return this.users?.find((u) => u.id === this.assigneeId) || null;
  }

  get titlePlaceholder(): string {
    if (!this.title?.length && !this.helper && this.taskUpdated) {
      return this.$t('page.tasks.removeTask').toString();
    }
    return this.$t('page.tasks.addTask').toString();
  }

  get displayDeadline(): string {
    if (!this.$date(this.deadLine!).diff(new Date(), 'day')) {
      return this.$t('dayjs.today').toString();
    }

    const nextYear =
      this.$date(this.deadLine!).year() > new Date().getFullYear();
    const format = nextYear ? 'D MMM YYYY' : 'D MMM ';
    return this.$date(this.deadLine!).format(format);
  }

  get calendarDeadLine(): string | null {
    return this.deadLine
      ? this.$date(this.deadLine).format('YYYY-MM-DD')
      : null;
  }

  created(): void {
    this.taskToEdit = instanceToInstance(this.task);
    this.init();
  }

  async init(): Promise<void> {
    this.setGlobalLoading(true);
    this.title = this.taskToEdit.title || null;
    this.deadLine = this.taskToEdit.deadLine
      ? this.$date(this.taskToEdit.deadLine, 'YYYY-MM-DD').endOf('day').toDate()
      : null;
    this.assigneeId = this.taskToEdit.assignee || null;
    this.taskUpdated = false;
    this.completed =
      this.taskToEdit && this.taskToEdit.status === TaskStatus.COMPLETED;
    await this.loadComments();
    this.setGlobalLoading(false);
  }

  async loadComments(): Promise<void> {
    const targetId = this.taskToEdit.canonicalId!;
    const commentsData = await this.$api.comment.fetchCommentsForTarget(
      targetId,
      false,
      false
    );
    this.commentsTask = commentsData.map((x: any) => Comment.compat(x));
    this.commentsTask = this.commentsTask.sort(
      (x, y) => +new Date(y.createdAt) - +new Date(x.createdAt)
    );
    const userIds = distinctValues(this.commentsTask, (x) => x.author);
    this.usersComments = await this.$datasource.user.listBatch(userIds);
  }

  onMenuOptionClick(option: MenuOption): void {
    switch (option.value) {
      case 'markAsDone': {
        this.$emit('taskCompleted', { task: this.taskToEdit, complete: true });
        this.completed = true;
        this.taskStatusReloadKey += 1;
        break;
      }
      case 'removeTask': {
        this.$emit('taskDeleted', { task: this.taskToEdit });
        break;
      }
      default: {
        console.warn('no option selected');
        break; /* Otherwise ESLint complains */
      }
    }
  }

  enableTitleEditor(): void {
    this.editTitle = true;
    this.focusOnEditor();
  }

  async commentKeyDown(event: KeyboardEvent): Promise<void> {
    if (event.key === 'Enter' && this.validateCommentEditor()) {
      const targetId = this.taskToEdit.canonicalId!;
      await this.$emit('addCommentToTask', {
        newCommentText: this.newCommentText,
        taskId: targetId,
        currentUserId: this.currentUser!.id!
      });
      this.setGlobalLoading(true);
      this.newCommentText = '';
      this.enableComments();
      setTimeout(async () => {
        await this.loadComments();
        this.enableComments();
        this.setGlobalLoading(false);
      }, 1000);
    }
  }

  validateCommentEditor(): boolean {
    return !(
      !this.newCommentText.replace(/(\r\n|\n|\r)/gm, '') ||
      !this.newCommentText.length
    );
  }

  titleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && this.readyToRemove) {
      this.$emit('taskDeleted', { task: this.taskToEdit });
      return;
    }
    if (event.key === 'Enter') {
      this.notifyUpdate();
    }
  }

  titleBlur(): void {
    this.editTitle = false;
    // Enable line below to report update in title blur
    // this.notifyUpdate();
  }

  enableComments(): void {
    this.showComments = !this.showComments;
  }

  changeTitle(newTitle: string): void {
    this.title = newTitle;
    this.taskUpdated = true;
    this.readyToRemove = !newTitle.length;
  }

  changeDeadline(date: string): void {
    this.deadLine = this.$date(date, 'YYYY-MM-DD').endOf('day').toDate();
    this.taskUpdated = true;
    this.notifyUpdate();
  }

  changeAssignee(user: User): void {
    this.assigneeId = user.id!;
    this.showUserSelector = false;
    this.taskUpdated = true;
    this.$forceUpdate();
    this.notifyUpdate();
  }

  notifyUpdate(): void {
    if (!this.taskUpdated || !this.title?.trim().length) {
      return;
    }
    this.taskToEdit.title = this.title!;
    this.taskToEdit.deadLine = this.deadLine!;
    this.taskToEdit.assignee = this.assigneeId!;
    const event = this.helper ? 'taskAdded' : 'taskUpdated';
    this.$emit(event, { task: this.taskToEdit });
    this.taskUpdated = false;
  }

  focusOnEditor(): void {
    const elName = `taskTitleEditor-${this.taskToEdit.id}`;
    const editor: any = this.$refs && this.$refs[elName];
    if (editor) {
      this.editTitle = true;
      editor.focus();
    }
  }

  onStatusToggle(value: boolean): void {
    this.$emit('taskCompleted', { task: this.taskToEdit, complete: value });
  }
}

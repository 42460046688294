export const LOGIN_QUERY_PARAMETER = 'lcq';
// To prevent the cookie from production bleeding into staging/dev
export const SECURITY_COOKIE_NAME = `cn_${process.env.NODE_ENV}`;

export interface ICookie {
  name: string;
  value: string;
  domain?: string;
}

export class CookieUtils {
  private static DELETE_EXPIRATION = 'Thu, 01 Jan 1970 00:00:01 GMT';

  static set(cookie: ICookie): void {
    document.cookie = CookieUtils.build(cookie);
  }

  static get(name: string): string | undefined {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i += 1) {
      const kv = cookies[i].split('=').map((x) => x.trim());
      if (kv?.length !== 2) {
        return undefined;
      }
      if (kv[0] === name) {
        return kv[1];
      }
    }
    return undefined;
  }

  static remove(name: string): void {
    document.cookie = `${name}=none;expires=${CookieUtils.DELETE_EXPIRATION}`;
  }

  private static build(cookie: ICookie) {
    let str = `${cookie.name}=${cookie.value}`;
    if (cookie.domain !== undefined) {
      str += `;domain=${cookie.domain}`;
    }
    return str;
  }
}

import Vue from 'vue';
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import locales from './vee-validate-i18n';
import i18n from '../i18n';
import type User from '../model/User';

const validateEmail = (email: string): boolean => {
  const reg = /\S+@\S+\.\S+/;
  return reg.test(email);
};

const validateEmails = (emails: string): boolean =>
  emails
    .split(/,| |;/)
    .map((email: string) => email.trim())
    .filter((email: string) => !!email)
    .every(validateEmail);

const validateLinkedInUrl = (url: string): boolean =>
  url.startsWith('https://www.linkedin.com/');

const hasEmailsSelected = (email: string, selectedEmails: any): boolean =>
  selectedEmails.length > 0;

const emailAlreadySelected = (email: string, selectedEmails: any): boolean =>
  selectedEmails.some((e: string) => e === email);

const emailAlreadyExists = (email: string, currentUsers: any): boolean =>
  currentUsers.some((e: User) => e.email === email);

const initializeVeeValidate = (): void => {
  localize(locales);
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(rules).forEach((rule: string) => extend(rule, rules[rule]));

  extend('checkLinkedInUrl', {
    message: () => i18n.t('error.invalidLinkedInUrl').toString(),
    validate: (e: string) => validateLinkedInUrl(e)
  });

  extend('number_required', {
    ...rules.required,
    message: i18n.t('error.numberRequired').toString()
  });

  extend('emails', {
    message: () => i18n.t('error.emails').toString(),
    validate: (emails: string) => validateEmails(emails)
  });

  extend('checkIfHasSelectedEmails', {
    message: () => i18n.t('error.emailAddressRequired').toString(),
    validate: (email: string, selectedEmails: any) =>
      !hasEmailsSelected(email, selectedEmails)
  });

  extend('checkIfEmailAlreadySelected', {
    message: () => i18n.t('error.emailAlreadySelected').toString(),
    validate: (email: string, selectedEmails: any) =>
      !emailAlreadySelected(email, selectedEmails)
  });

  extend('checkIfEmailAlreadyExists', {
    message: () => i18n.t('error.emailAlreadyExists').toString(),
    validate: (email: string, currentUsers: any) =>
      !emailAlreadyExists(email, JSON.parse(currentUsers))
  });
};
export default initializeVeeValidate;

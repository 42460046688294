










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'SecondaryButton',
  inheritAttrs: false,
  components: {
    BaseButton
  }
})
export default class SecondaryButton extends Vue {}

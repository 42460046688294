import Vue from 'vue';
import dayjs from 'dayjs';
import { merge } from 'lodash';
import type { LocaleMessages } from 'vue-i18n';
import VueI18n from 'vue-i18n';
import { localize } from 'vee-validate';

Vue.use(VueI18n);

export function getBrowserLanguage(): string {
  return window.navigator.language.split('-')[0].toLowerCase();
}

const isDev = process.env.NODE_ENV === 'development';

const currentUserLanguage = getBrowserLanguage();

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    './locales',
    false,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18nInstance = new VueI18n({
  locale: currentUserLanguage || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: !isDev
});

i18nInstance.missing = (locale, key, vm, values) => {
  // TODO: remove this when all translations are in the correct format
  // Handle translation missing by removing the last segment of the key and translating the new key
  const newKey = key.split('.').slice(0, -1).join('.');
  const translation = vm?.$te(newKey)
    ? vm?.$t(newKey, values).toString()
    : null;
  if (!translation) {
    return isDev ? `[missing ${key}]` : '';
  }
  return translation;
};

// TODO: remove this when all custom translations are loaded from the workflow
function loadProductOverrides(lang: string, productId: string): void {
  const productTranslations = require.context(
    './locales/productSpecific',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const translationKey = `./${productId}_${lang}.json`;

  if (productTranslations.keys().includes(translationKey)) {
    const productSpecificMessages = productTranslations(translationKey);
    const messages = merge(
      i18nInstance.messages[lang],
      productSpecificMessages
    );
    i18nInstance.setLocaleMessage(lang, messages);
  }
}

function addProductTranslationOverrides(
  languageMap: Record<string, unknown>
): void {
  Object.entries(languageMap).forEach(([lang, productSpecificMessages]) => {
    const messages = merge(
      i18nInstance.messages[lang],
      productSpecificMessages
    );
    i18nInstance.setLocaleMessage(lang, messages);
  });
}

function setI18nLanguage(lang: string, productId: string): void {
  loadProductOverrides(lang, productId);
  i18nInstance.locale = lang;
  localize(lang);
  dayjs.locale(lang);
  document.querySelector('html')!.setAttribute('lang', lang);
}

export default i18nInstance;

export { setI18nLanguage, addProductTranslationOverrides };
















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Ticket from '@rready/common-utils/src/model/Ticket';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import { WorkflowV2 } from '@rready/common-utils/src/model/workflow/v2/WorkflowV2';
import BaseVideoUpload from '../widgets/BaseVideoUpload.vue';

type MediaObjects = Record<string, FileModel>;

@Component({
  name: 'BaseProject',
  components: {
    BaseVideoUpload
  }
})
export default class BaseProject extends Vue {
  @Prop({ default: () => new Ticket('') }) readonly ticket!: Ticket;

  @Prop({ default: () => ({}) }) readonly providedVideoPitches!: Record<
    string,
    string
  >;

  @Getter getCurrentContext!: string;

  videos: MediaObjects = {};

  videoPitchesMap: Record<string, string> = {};

  videoPitches: string[] = [];

  get videoPitchTypes(): string[] {
    let prevValue = true;
    // Only return the submitted videos and the first next video
    return Object.values(this.videoPitches).filter((pitch: string) => {
      const hasValue = !!this.ticket.getPitch(pitch) || !!this.videos[pitch];
      const result: boolean = hasValue || (!hasValue && prevValue);
      prevValue = hasValue;
      return result;
    });
  }

  async created(): Promise<void> {
    await this.setVideoPitches();
    this.setInitialVideos();
  }

  addVideos(pitch: string, video: FileModel): void {
    this.videos[pitch] = video;
  }

  @Watch('ticket.files')
  setInitialVideos(): void {
    this.videos = Object.values(this.videoPitches).reduce(
      (st: MediaObjects, videoPitch: string) => {
        st[videoPitch] = this.ticket.getPitch(videoPitch)!;
        return st;
      },
      {}
    );
  }

  @Watch('ticket.type')
  async setVideoPitches(): Promise<void> {
    const workflowData = await this.$datasource.workflow.find(
      this.ticket.type.toLowerCase(),
      this.getCurrentContext
    );
    const workflow = new WorkflowV2(workflowData!);

    // Either get the video from the props, or from the workflow
    if (Object.keys(this.providedVideoPitches).length > 0) {
      this.videoPitchesMap = this.providedVideoPitches;
    } else {
      this.videoPitchesMap = workflow.videoPitches;
    }
    // Get all the unique video pitch types
    this.videoPitches = [...new Set(Object.values(this.videoPitchesMap))];
  }
}













































import { Component, Prop } from 'vue-property-decorator';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import type { FileModel } from '@rready/common-utils/src/types/FileModel';
import videoIcon from '../../assets/video.svg';
import filesIcon from '../../assets/files.svg';
import BaseControlWrapper from '../core/BaseControlWrapper.vue';
import BaseFileCard from './BaseFileCard.vue';
import BaseMenu from './BaseMenu.vue';
import BasePill from './BasePill.vue';
import BaseUpload from './BaseUpload.vue';
import BaseFormControl from '../../helpers/BaseFormControl';

@Component({
  name: 'BaseTicketFileUpload',
  components: {
    BaseControlWrapper,
    BaseFileCard,
    BaseUpload,
    BasePill,
    BaseMenu
  }
})
export default class BaseTicketFileUpload extends BaseFormControl {
  @Prop({ default: '' }) readonly fileType!: string;

  @Prop({ default: '' }) readonly objectId!: string;

  @Prop({ default: '' }) readonly category!: string;

  @Prop({ default: null }) readonly file!: any;

  uploading = false;

  videoIcon = videoIcon;

  mediaObject: FileModel | null = null;

  menuOptions: MenuOption[] = [
    {
      title: this.$t('menu.remove').toString(),
      value: 'remove'
    }
  ];

  created(): void {
    super.created();
    this.setFile();
  }

  beforeUpdate(): void {
    this.setFile();
  }

  setFile(): void {
    this.mediaObject = this.file as FileModel;
    this.uploading = !this.file?.url;
    this.$emit('input', this.mediaObject);
  }

  onMenuOptionClick(): void {
    this.$emit('uploadCancelled', this.mediaObject);
    this.mediaObject = null;
  }

  setMediaObject(newMediaObject: FileModel): void {
    if (!this.uploading) {
      return;
    }
    this.uploading = false;
    this.mediaObject = newMediaObject;
    if (this.mediaObject) {
      if (this.mediaObject.contentType?.startsWith('video')) {
        this.mediaObject.url = this.videoIcon;
      }
      if (this.mediaObject.contentType?.startsWith('application')) {
        this.mediaObject.url = filesIcon;
      }
      this.$emit('addNewFile', this.mediaObject);
    } else {
      this.$emit('uploadCancelled', this.file);
    }
    this.$emit('input', this.mediaObject);
  }
}

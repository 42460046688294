
















































































import { Component, Prop } from 'vue-property-decorator';
import { get } from 'lodash';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseSelect',
  components: {
    BaseControlWrapper
  }
})
export default class BaseSelect extends BaseFormControl {
  @Prop({ default: () => [] }) items!: Promise<unknown[]>;

  resolvedItems: unknown[] = [];

  resolvingItems = true;

  get hasSelectionSlot(): boolean {
    return !!this.$scopedSlots.selection;
  }

  async created(): Promise<void> {
    super.created();
    this.resolvedItems = await this.items;
    this.resolvingItems = false;
  }

  getDisplayText(
    item: Record<string, string> | string,
    itemText: string
  ): string {
    if (typeof item === 'object') {
      return get(item, itemText || 'text');
    }
    return item;
  }
}

import { render, staticRenderFns } from "./BaseUserAssign.vue?vue&type=template&id=78a3d68b&scoped=true&"
import script from "./BaseUserAssign.vue?vue&type=script&lang=ts&"
export * from "./BaseUserAssign.vue?vue&type=script&lang=ts&"
import style0 from "./BaseUserAssign.vue?vue&type=style&index=0&id=78a3d68b&prod&lang=scss&scoped=true&"
import style1 from "./BaseUserAssign.vue?vue&type=style&index=1&id=78a3d68b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a3d68b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemTitle})

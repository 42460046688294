















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Provide } from 'vue-property-decorator';
import type User from '@rready/common-utils/src/model/User';
import NavigationDrawer from '@rready/common-components/src/components/admin/NavigationDrawer.vue';
import Snackbar from '@rready/common-components/src/components/widgets/Snackbar.vue';
import LoadingView from '@rready/common-components/src/components/widgets/LoadingView.vue';
import type { MenuOption } from '@rready/common-utils/src/types/MenuOption';
import type { IProductConfigCompat } from '@rready/common-utils/src/model/workflow/IProductConfigCompat';
import TicketService from '@rready/common-utils/src/services/TicketService';
import { StorageUtils } from '@rready/common-utils/src/utils/storage';
import analyticsService from '@rready/common-utils/src/services/analyticsService';
import type Organization from '@rready/common-utils/src/model/Organization';
import Header from './components/core/Header.vue';
import AppBody from './components/core/AppBody.vue';
import Footer from './components/core/Footer.vue';

@Component({
  components: {
    NavigationDrawer,
    Header,
    AppBody,
    Snackbar,
    Footer,
    LoadingView
  }
})
export default class App extends Vue {
  @Getter currentUser?: User;

  @Getter getProductConfig!: IProductConfigCompat;

  @Getter getContextLoaded!: boolean;

  @Getter getCurrentProductId!: string;

  @Getter getOrganization!: Organization;

  @Getter showGlobalLoading!: boolean;

  get productId(): string {
    if (this.getContextLoaded) {
      return this.getCurrentProductId;
    }

    return '';
  }

  @Provide() trackClickEvent = (title: string): void => {
    analyticsService.trackClickEvent(
      title,
      this.$route.name!,
      this.currentUser!,
      this.getOrganization
    );
  };

  navigationOptions: MenuOption[] = [
    {
      title: this.$t('menu.tickets').toString(),
      icon: 'fa-lightbulb',
      value: 'Tickets'
    },
    {
      title: this.$t('menu.board').toString(),
      icon: 'fa-th-large',
      value: 'OverviewBoard'
    },
    {
      title: this.$t('menu.users').toString(),
      icon: 'fa-user',
      value: 'Users'
    },
    {
      title: this.$t('menu.teams').toString(),
      icon: 'fa-user-friends',
      value: 'Teams'
    },
    {
      title: this.$t('menu.topics').toString(),
      icon: 'fa-comment-alt-lines',
      value: 'Topics'
    },
    {
      title: this.$t('menu.coaching').toString(),
      icon: 'fa-envelopes-bulk',
      value: 'Coaching'
    },
    {
      title: this.$t('menu.emailCommunication').toString(),
      icon: 'fa-paper-plane',
      value: 'EmailCommunication'
    },
    {
      title: this.$t('menu.assessments').toString(),
      icon: 'fa-question-circle',
      value: 'Assessments',
      refresh: true
    }
  ];

  get enabledNavigationOptions(): MenuOption[] {
    return (
      this.navigationOptions
        // First, filter out the disabled routes in the submenus
        .reduce((acc, option): MenuOption[] => {
          const subMenus = (option.subMenus || []).filter((subOption) =>
            this.isPageEnabled(subOption.value!)
          );
          acc.push({
            ...option,
            subMenus
          });

          return acc;
        }, [] as MenuOption[])
        // Second, filter out disabled the main routes, skip if there are subMenus
        .filter(
          (option) =>
            (option.subMenus && option.subMenus.length > 0) ||
            (!!option.value && this.isPageEnabled(option.value!))
        )
    );
  }

  @Provide() ticketNavigation = (canonicalId: string): void => {
    const productId = StorageUtils.getProductId() ?? '';
    TicketService.openTicketInPortal(productId, canonicalId);
  };

  isPageEnabled(routeName: string): boolean {
    return this.getProductConfig.getPageSettings(routeName)?.enabled ?? true;
  }
}





























import { Component, Prop } from 'vue-property-decorator';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  inheritAttrs: false,
  name: 'BaseRadioGroup',
  components: {
    BaseControlWrapper
  }
})
export default class BaseRadioGroup extends BaseFormControl {
  @Prop({ default: () => [] }) items!: Array<{ label: string; value: string }>;

  created(): void {
    super.created();
  }
}

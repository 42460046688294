















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class BaseHelp extends Vue {
  @Prop({ default: null }) title!: string;

  @Prop({ default: null }) description!: string;
}

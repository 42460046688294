




























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import BaseModal from './BaseModal.vue';
import BaseTextArea from '../core/BaseTextArea.vue';

@Component({
  name: 'BaseConfirmationModal',
  components: {
    BaseModal,
    PrimaryButton,
    SecondaryButton,
    BaseTextArea
  }
})
export default class BaseConfirmationModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: '' }) readonly contentClass!: string;

  @Prop({ default: '688' }) readonly cardWidth!: string;

  @Prop({ default: '706' }) readonly cardHeight!: string;

  @Prop({ default: false }) readonly inProgress!: boolean;

  @Prop({ default: 'far fa-trash-alt' }) readonly icon!: string;

  @Prop({ default: false }) readonly showReason!: boolean;

  @Prop({ default: null }) readonly reason!: string;

  @Prop({ default: '' }) readonly field!: string;

  @Prop({ default: 'modal.' }) readonly fieldPrefix!: string;

  reasonText = '' ?? this.reason;

  get primaryButtonTitle(): string {
    return (
      this.fetchFieldTranslation('confirm') ||
      this.$t('form.button.confirm').toString()
    );
  }

  get secondaryButtonTitle(): string {
    return (
      this.fetchFieldTranslation('cancel') ||
      this.$t('form.button.cancel').toString()
    );
  }

  get modalTitle(): string {
    return (
      this.fetchFieldTranslation('title') ||
      this.$t('common.askConfirmation').toString()
    );
  }

  get description(): string {
    return this.fetchFieldTranslation('description') || '';
  }

  get reasonLabel(): string {
    return (
      this.fetchFieldTranslation('reason') ||
      this.$t('form.reason.label').toString()
    );
  }

  async confirm(): Promise<void> {
    if (!(await this.validate())) {
      return;
    }

    this.$emit('confirm', this.reasonText);
  }

  fetchFieldTranslation(type: string): string {
    if (this.$te(`${this.fieldPrefix}${this.field}.${type}`)) {
      return this.$t(`${this.fieldPrefix}${this.field}.${type}`).toString();
    }
    return '';
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }
}







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import styleVariables from '../../styles/export.module.scss';

@Component({
  name: 'BaseAddButton'
})
export default class BaseAddButton extends Vue {
  @Prop({ default: 'Add' }) text!: string;

  @Prop({ default: '100%' }) cardWidth!: string;

  @Prop({ default: '85' }) cardHeight!: string;

  @Prop({ default: false }) isDisabled!: boolean;

  @Prop({ default: true }) showIcon!: boolean;

  styleVariables = styleVariables;

  get _cardWidth(): string {
    return Number.isNaN(this.cardWidth)
      ? this.cardWidth
      : `${this.cardWidth}px`;
  }

  get _cardHeight(): string {
    return Number.isNaN(this.cardHeight)
      ? this.cardHeight
      : `${this.cardHeight}px`;
  }

  get cardColor(): any {
    return this.isDisabled
      ? styleVariables.lightGrey
      : styleVariables.secondaryBlue;
  }

  ruId(): string {
    const ruidString = `button-${this.text}`;
    return ruidString.replace(/\s+/g, '-').toLowerCase();
  }

  onClick(event: Event): void {
    if (this.isDisabled) {
      event?.stopPropagation();
    }
  }
}

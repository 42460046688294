















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'VideoPlayback'
})
export default class VideoSwitcher extends Vue {
  @Prop({ default: '' }) readonly url!: string;

  currentTime = -1;

  get language(): string {
    return this.$i18n?.locale || 'en';
  }

  resume(): void {
    if (this.currentTime !== -1) {
      const videoElement = this.$refs.video as HTMLVideoElement;
      videoElement.currentTime = this.currentTime;
      videoElement.play();
      this.currentTime = -1;
    }
  }
}

export enum HistoryEventType {
  DETAILS_UPDATED = 'DETAILS_UPDATED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  TASK_UPDATED = 'TASK_UPDATED',
  FILE_ADDED = 'FILE_ADDED',
  FILE_REMOVED = 'FILE_REMOVED',
  FILE_UPDATED = 'FILE_UPDATED',
  ASSIGNEE_CHANGED = 'ASSIGNEE_CHANGED'
}

export default class TicketHistory {
  createdDate: Date | undefined;

  creatorUser: string | undefined;

  eventType: HistoryEventType | undefined;

  field: string | undefined;

  newValue: string | undefined;

  previousValue: string | undefined;
}

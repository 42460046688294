import Vue from 'vue';
import 'reflect-metadata';
import InlineSvg, { InlineSvgPlugin } from 'vue-inline-svg';
import { SdkPluginVue2 } from '@rready/sdk';
import i18n from '@rready/common-utils/src/i18n';
import initializeVeeValidate from '@rready/common-utils/src/plugins/vee-validate';
import initTagManager from '@rready/common-utils/src/plugins/googleTagManager';
import initSentry from '@rready/common-utils/src/plugins/sentry';
import DayjsPlugin from '@rready/common-utils/src/plugins/dayjs';
import vuetify from '@rready/common-components/src/plugins/vuetify';
import '@rready/common-utils/src/plugins/polyfill';
import { rreadyDynamicFields } from '@rready/dynamic-fields';
import '@rready/dynamic-fields/dist/style.css'; // TODO Inline styles to design bundle
import { rreadyVuetifyUi } from '@rready/common-components/src/library';
import { SdkOptions } from './plugins/SdkConfiguration';
import App from './App.vue';
import './registerServiceWorker';
import VueRouter from './router';
import store from './store';

const isDev = process.env.NODE_ENV === 'development';

Vue.config.productionTip = isDev;

initSentry('rready-admin');
initializeVeeValidate();

// Register the components for BaseDynamicField.vue
Vue.use(rreadyVuetifyUi);
Vue.use(rreadyDynamicFields);

Vue.use(DayjsPlugin);
Vue.use(InlineSvgPlugin);
Vue.use(SdkPluginVue2, SdkOptions);
Vue.component('inline-svg', InlineSvg);

VueRouter.then((router) => {
  initTagManager(router, process.env.VUE_APP_GA_ADMIN);
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app');
});

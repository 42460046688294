

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'LoadingView'
})
export default class LoadingView extends Vue {
  @Prop({ default: 'main' }) readonly id!: string;

  @Prop({ default: false }) readonly loading!: boolean;

  @Prop({ default: false }) readonly fullPage!: boolean;

  @Prop({ default: 40 }) readonly size!: number;

  get spinnerStyle(): Partial<CSSStyleDeclaration> {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`
    };
  }
}

/* eslint-disable import/prefer-default-export */
import type { IClientOptions } from '@rready/sdk';
import { getCurrentAuth } from '@rready/common-utils/src/services/SecurityService';
import { StorageUtils } from '@rready/common-utils/src/utils/storage';

export const SdkOptions: IClientOptions = {
  baseUrl: `${process.env.VUE_APP_API_URL}`,
  contextOptions: {
    getAppId: () => process.env.VUE_APP_APP_NAME ?? '',
    getContext: () => getCurrentAuth()?.context ?? '',
    getProduct: () => StorageUtils.getProductId() ?? '',
    getToken: () => getCurrentAuth()?.token ?? ''
  },
  handlerOptions: {
    onAuthError: (message: string) => {
      console.error(`Auth error: ${message}`);
    },
    onClientError: (message: string) => {
      console.error(`Client error: ${message}`);
    },
    onServerError: (message: string) => {
      console.error(`Server error: ${message}`);
    },
    onError: (message: string) => {
      console.error(`Generic error: ${message}`);
    }
  }
};

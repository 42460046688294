import Vue from 'vue';
import type { Store } from 'vuex';
import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import VueRouter from './VueRouteOverride';
import processRoute from './guard';
import featureCheck from './featureGuard';

Vue.use(VueRouter);

const getBase = async (
  isAdmin: boolean,
  store: Store<any>
): Promise<string> => {
  let base: string = process.env.BASE_URL || '/';
  // Fetch current user
  if (store.getters.isAuthenticated && !store.getters.currentUser) {
    await store.dispatch('fetchCurrentUser');
  }

  if (isAdmin) {
    base = `${base}admin`;
  } else if (store.getters.isAuthenticated) {
    base = `${base}${store.getters.getCurrentProductId}`;
  }

  return base;
};

const router = async (
  routes: Array<RouteConfig>,
  store: Store<any> /* Vuex.Store */,
  isAdmin = false
): Promise<VueRouter> => {
  const vueRouter = await new VueRouter({
    mode: 'history',
    base: await getBase(isAdmin, store),
    routes: [
      {
        path: '/logout',
        name: 'Logout',
        meta: {
          publicAccess: true
        },
        async beforeEnter() {
          await store.dispatch('logout');
          window.location.href = process.env
            .VUE_APP_AUTHENTICATION_URL as string;
        }
      },
      ...routes
    ],
    scrollBehavior(to, from): void {
      const hasQueryParams = !!Object.keys(to.query).length;
      const differentRoute = from.path !== to.path;

      if (differentRoute || !hasQueryParams) {
        if (!to.hash) {
          // instantly scroll to top without smooth transition
          window.scrollTo(0, 0);
          return;
        }

        setTimeout(() => {
          const element = document.querySelector(to.hash) as HTMLElement;
          window.scrollTo({
            top: element?.offsetTop || 0,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  });

  vueRouter.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) =>
      processRoute(to, from, next, store, isAdmin)
  );

  vueRouter.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) =>
      featureCheck(to, from, next, store)
  );

  return vueRouter;
};

export default router;

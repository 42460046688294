import { plainToClass } from 'class-transformer';
import TicketDelegationType from '../types/TicketDelegationType';
import type { IDelegatable } from './Delegate';
import Delegate from './Delegate';

export enum TEAM_TYPE {
  ORGANIZATIONAL_TEAM = 'ORGANIZATIONAL_TEAM',
  VIRTUAL_TEAM = 'VIRTUAL_TEAM'
}

// IP-2228 Review
export interface ITeamCompat {
  context: string;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  type: 'ORGANIZATIONAL_TEAM' | 'VIRTUAL_TEAM';
}
export default class Team implements IDelegatable {
  public static compat(value: ITeamCompat): Team {
    return plainToClass(Team, value);
  }

  id: string | null;

  name: string;

  description: string;

  type: TEAM_TYPE;

  createdBy: string | undefined;

  constructor(
    id: string | null,
    name: string,
    description: string,
    type: TEAM_TYPE
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
  }

  asDelegate(): Delegate {
    if (!this.id) {
      throw new Error('Could not convert Team into delegate');
    }
    return new Delegate(this.id, 'team');
  }

  getDelegateId(): string {
    console.warn(
      `Topic 'getDelegateId' is obsolete [${TicketDelegationType.TEAM}:${this.id}]`
    );
    return `${TicketDelegationType.TEAM}:${this.id}`;
  }
}

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const isDev = process.env.NODE_ENV === 'development';

const initSentry = (appName: string): void => {
  Sentry.init({
    release: `${appName}@${process.env.VUE_APP_VERSION}`,
    dsn: isDev ? '' : process.env.VUE_APP_SENTRY_URL,
    environment: process.env.NODE_ENV,
    logErrors: isDev,
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true
    },
    tracesSampleRate: 0.1,
    Vue
  });
};

export default initSentry;

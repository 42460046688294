import Vue from 'vue';
import VueGtag from 'vue-gtag';
import type { Route } from 'vue-router';
import type VueRouter from '../router/VueRouteOverride';

const initTagManager = (
  router: VueRouter,
  analyticsId: string | undefined
): void => {
  Vue.use(
    VueGtag,
    {
      config: { analyticsId },
      pageTrackerTemplate(to: Route) {
        return {
          page_title: to.name,
          page_path: to.path,
          page_location: to.query.page,
          send_page_view: true
        };
      }
    },
    router
  );
};

export default initTagManager;

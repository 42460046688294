import { plainToClass } from 'class-transformer';
import type { IOrganization, IProductCustomization } from '@rready/sdk';
import CommonDto from './CommonDto';
import type Content from '../types/Content';

export default class Organization extends CommonDto {
  public static compat(value: IOrganization): Organization {
    return plainToClass(Organization, value);
  }

  id: string | undefined;

  shortCode: string | undefined;

  domains: Array<string> | undefined;

  name: string | undefined;

  enabled: boolean | undefined;

  enabledProducts!: string[];

  context: string | undefined;

  defaultLanguage: string | undefined;

  logo: string | undefined;

  productCustomization: Record<string, IProductCustomization> | undefined;

  availableLanguages: Array<string> = [];

  termsAndConditions: Content | null = null;

  projectDeal: Content | null = null;

  getProjectDeal(language: string): string {
    return (
      this.projectDeal?.content[language.toUpperCase()] ||
      this.projectDeal?.content.EN ||
      ''
    );
  }

  getTermsAndConditionsLink(language: string): string | null {
    if (
      this.termsAndConditions &&
      this.termsAndConditions.content[language.toUpperCase()]
    ) {
      const value = this.termsAndConditions?.content[language.toUpperCase()];
      const linkValues = value ? value.split('||') : [];
      return linkValues.length ? linkValues[0] : null;
    }
    return null;
  }

  getPrivacyPolicyLink(language: string): string | null {
    if (
      this.termsAndConditions &&
      this.termsAndConditions.content[language.toUpperCase()]
    ) {
      const value = this.termsAndConditions?.content[language.toUpperCase()];
      const linkValues = value ? value.split('||') : [];
      return linkValues.length ? linkValues[1] : null;
    }
    return null;
  }
}

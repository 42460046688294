import { render, staticRenderFns } from "./BaseFilesDisplay.vue?vue&type=template&id=0086d4ec&scoped=true&"
import script from "./BaseFilesDisplay.vue?vue&type=script&lang=ts&"
export * from "./BaseFilesDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFilesDisplay.vue?vue&type=style&index=0&id=0086d4ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0086d4ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VImg,VProgressCircular,VRow})

import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=5dc1077e&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=5dc1077e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_a7da0cd9159f811181693b0558034480/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_538fe7f7f30a7a9e34ddd8f2367e48e4/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})

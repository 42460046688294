


































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import type { ActionMethod } from 'vuex';
import type Team from '@rready/common-utils/src/model/Team';
import Topic from '@rready/common-utils/src/model/Topic';
import User from '@rready/common-utils/src/model/User';
import type { TicketAssignment } from '@rready/common-utils/src/types/TicketAssignment';
import type Ticket from '@rready/common-utils/src/model/Ticket';
import type { DelegateDomain } from '@rready/common-utils/src/model/Delegate';
import Delegate from '@rready/common-utils/src/model/Delegate';
import BaseRadioGroup from '../core/BaseRadioGroup.vue';
import TeamSelector from './TeamSelector.vue';
import TopicSelector from './TopicSelector.vue';
import UserSelector from './UserSelector.vue';
import BaseSelect from '../core/BaseSelect.vue';

type AssignOption = { label: string; value: DelegateDomain };

@Component({
  name: 'BaseAssignTicketTo',
  components: {
    BaseRadioGroup,
    BaseSelect,
    TeamSelector,
    TopicSelector,
    UserSelector
  }
})
export default class BaseAssignTicketTo extends Vue {
  @Prop({ default: '0' }) cardHeight!: string;

  @Prop({ default: null }) ticket!: Ticket;

  @Prop({ default: null }) readonly assignTypes!: string[];

  @Getter currentUser?: User;

  @Getter organizationalUnits?: Team[];

  @Action fetchUserTeams!: ActionMethod;

  initialized = false;

  isLoadingDelegateValues = false;

  selectedDelegateType: DelegateDomain | null = null;

  selectedDelegateValue: string | null = null;

  userTeams: Team[] = [];

  orgTeams: Team[] = [];

  topics: Topic[] = [];

  get ticketDelegate(): Delegate | undefined {
    return this.ticket.getDelegateCompat();
  }

  get defaultDomain(): DelegateDomain {
    return 'team';
  }

  get defaultDelegateDomain(): DelegateDomain {
    return this.assignOptions?.[0]?.value ?? this.defaultDomain;
  }

  get assignOptions(): AssignOption[] {
    const allOptions: Record<string, AssignOption> = {
      team: {
        label: this.$t('form.ticketAssignToTeam').toString(),
        value: 'team'
      },
      topic: {
        label: this.$t('form.ticketAssignToTopic').toString(),
        value: 'topic'
      },
      user: {
        label: this.$t('form.ticketAssignToUser').toString(),
        value: 'user'
      }
    };

    return this.assignTypes?.map((type: string) => allOptions[type]);
  }

  async created(): Promise<void> {
    await this.setAssignTicketTo();
    this.initialized = true;
  }

  @Watch('assignTypes')
  async setAssignTicketTo(): Promise<void> {
    const routeParams: any = this.$route.params;
    if (routeParams && routeParams.teamId) {
      this.setTeam();
    } else if (routeParams && routeParams.topicId) {
      this.setTopic();
    } else {
      this.selectedDelegateType =
        this.ticketDelegate?.domain ?? this.defaultDelegateDomain;
      this.selectedDelegateValue = this.ticketDelegate?.id ?? null;
    }

    await this.fetchDataForDelegateType(this.selectedDelegateType!);
  }

  setTopic(): void {
    this.selectedDelegateType = 'topic';
    this.setDelegate(this.$route.params.topicId);
  }

  setTeam(): void {
    this.selectedDelegateType = 'team';
    this.setDelegate(this.$route.params.teamId);
  }

  async fetchDataForDelegateType(domain: DelegateDomain): Promise<void> {
    this.isLoadingDelegateValues = true;

    if (domain === 'team') {
      await this.initTeams();
    } else if (domain === 'topic') {
      await this.initTopics();
    }

    this.isLoadingDelegateValues = false;
  }

  async initTeams(): Promise<void> {
    try {
      this.userTeams = await this.fetchUserTeams(this.currentUser?.id);
    } catch {
      this.userTeams = [];
    }

    this.orgTeams = this.organizationalUnits ?? [];
  }

  async initTopics(): Promise<void> {
    const topicsCompat = await this.$datasource.topic.list();
    this.topics = topicsCompat.map((x) => Topic.compat(x));
  }

  delegateTypeChanged(selectedDomain: DelegateDomain): void {
    this.selectedDelegateValue = null;
    this.fetchDataForDelegateType(selectedDomain);
  }

  async setDelegate(delegateId: string): Promise<void> {
    this.selectedDelegateValue = delegateId;
    this.$emit('input', await this.fetchAssignment());
  }

  // We don't want to store the delegate type 'USER' in the database.
  // The user should instead be stored in 'ticket.assignee' and
  // the ticket should be delegated to the team of the assigned user.
  async delegateUserToTeam(): Promise<TicketAssignment> {
    const userCompat = await this.$datasource.user.findById(
      this.selectedDelegateValue!
    );
    const user = User.compat(userCompat!);
    const creatorTeam = this.currentUser?.unit;
    const assignee = user?.id;
    const delegate = new Delegate(user?.unit ?? '', 'team');

    return {
      creatorTeam,
      assignee,
      delegate: [delegate]
    };
  }

  async fetchAssignment(): Promise<TicketAssignment> {
    if (this.selectedDelegateType === 'user') {
      return this.delegateUserToTeam();
    }
    const assignee = undefined;
    const creatorTeam = this.currentUser?.unit;
    const delegate = new Delegate(
      this.selectedDelegateValue ?? '',
      this.selectedDelegateType ?? this.defaultDomain
    );

    return {
      creatorTeam,
      assignee,
      delegate: [delegate]
    };
  }
}
